import { DateType } from 'src/shared/ui/datePicker';
import { Utils } from 'src/shared/lib';

import styles from './OrdersStatus.module.less';

interface OrdersStatusProps {
  status: string;
  createdAt: DateType;
}

export const OrdersStatus = ({ status, createdAt }: OrdersStatusProps) => (
  <article className={styles.ordersStatus}>
    <div className={styles.info}>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Статус</h3>
        <strong>{status || '-'}</strong>
      </div>
      <p>Создан {Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm')}</p>
    </div>
  </article>
);
