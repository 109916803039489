import { useState, RefObject, useEffect } from 'react';
import { ChatMessagesResponseData } from 'src/shared/api/queries/chatMessagesApi';

import { Utils } from '../utils';

interface IPaginationHook {
  fetcher: (pageNumber: number) => void;
  listRef: RefObject<HTMLUListElement> | RefObject<HTMLDivElement> | null;
  wrapperRef: RefObject<HTMLDivElement> | null;
  lastPage: number;
  chatId?: number | null;
  scrollBottom?: boolean;
  messageHistory?: ChatMessagesResponseData | null;
}

export const useInfinitePagination = ({
  fetcher,
  listRef,
  wrapperRef,
  lastPage,
  scrollBottom,
  messageHistory,
  chatId,
}: IPaginationHook) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    setPageNumber(1);
  }, [chatId]);

  useEffect(() => {
    if (!messageHistory) return;

    const devider = pageNumber === 1 ? 2 : 4;

    if (wrapperRef && wrapperRef.current) {
      // TODO сделать плавный скролл
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight / devider;
    }
  }, [messageHistory]);

  const handleScroll = async () => {
    if (pageNumber >= lastPage) return;

    if (wrapperRef && wrapperRef.current && listRef && listRef.current) {
      const scrolledFromTop = wrapperRef.current.scrollTop;
      const maxScroll = wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight;
      const scrolledFromBottom = maxScroll - wrapperRef.current.scrollTop;
      const scrolled = scrollBottom ? scrolledFromBottom : scrolledFromTop;

      if (scrolled) {
        const height = listRef.current.offsetHeight;
        const screenHeight = wrapperRef.current.offsetHeight;
        const threshold = height - screenHeight / 3;
        const position = scrolled + screenHeight;

        if (position >= threshold) {
          const nextPage = pageNumber + 1;
          if (nextPage <= lastPage) {
            await fetcher(nextPage);
            setPageNumber(nextPage);
          }
        }
      }
    }
  };

  return { handleScroll: Utils.throttle(handleScroll, 250) };
};
