import { useStore } from 'effector-react';
import Echo from 'laravel-echo';
import { useEffect } from 'react';
import { $echoTestClients, $elIndex , $activeChat } from 'src/shared/api/model';
import { Message } from 'src/shared/types';
import { getTestWSMessagesEvent } from 'src/pages/settings/chatTest/model';


/**
 * Подписка на новые сообщения
 */
export const useTestEchoNewMessage = (chats: { id: number; access_token: string }[]) => {
  const echoClient = useStore($echoTestClients);
  const activeChat = useStore($activeChat);
  const idx = useStore($elIndex);

  useEffect(() => {

    const channelChatId = `.${activeChat.id}`;
    const eventNewMessageChat = 'chat.message.created';

    const listenEvent = async <T>({
      echo,
      channel,
      event,
      callback,
    }: {
      echo: Echo | null;
      channel: string;
      event: string;
      callback: (data: T) => void;
    }) => {
      echo?.join(channel).listen(event, () => {});

      echo?.listen(`private-client${channel}`, `.${event}`, (data: T) => {
        callback(data);
      });
    };

    listenEvent<Message>({
      echo: echoClient,
      channel: channelChatId,
      event: eventNewMessageChat,
      callback: (data: Message) => getTestWSMessagesEvent(data),
    });

    return () => {
      echoClient?.leaveChannel(`private-client${channelChatId}`);
      // resetPrevWSMessages();
    };
    // });
  }, [activeChat?.id, chats, echoClient, idx]);
};
