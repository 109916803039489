import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Utils , getFormattedPhone } from 'src/shared/lib';
import { DateType } from 'src/shared/ui';

import { NameProfilesCard } from './ui';
import { Client } from './types';
import { renderClientsStatus } from '../clientsProfile';

enum statusesEnum {
  ACTIVE = 'Активный',
  BANNED = 'Бан',
  DELETED = 'Удален',
  FULL_DELETED = 'Полностью удален',
}

export const statusOptions = Object.entries(statusesEnum).map(([key, value]) => ({
  label: value,
  value: key,
}));

export enum MenuTableEnum {
  EDIT = 'edit',
  DUBLICATE = 'duplicate',
  ARCHIVE = 'archive',
}

export const regClientStatuses = ["ACTIVE", "BANNED", "DELETED", "FULL_DELETED"];

export const mainColumnName = 'name';

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const columns: ColumnsType<Client> = [
  {
    title: 'ФИО',
    key: 'name',
    render: (data, record) => (
      <Link to={`/profile/client/${record.id}`} state={{ prevPath: true }}>
        <NameProfilesCard data={data} />
      </Link>
    ),
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    key: 'email',
    render: data => data || '-',
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
    render: data => getFormattedPhone(data) || '-',
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => (data ? Utils.dateFormat(data) : '-'),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: status => (status ? renderClientsStatus(status) : '-'),
  },
  // TODO: добавить в следующей итерации
  // {
  //   title: '',
  //   key: 'settings',
  //   render: () => (
  //     <Dropdown overlay={menu}>
  //       <a href="#0">
  //         <MoreOutlined />
  //       </a>
  //     </Dropdown>
  //   ),
  // },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
