import { Rule } from 'antd/lib/form';
import { EOrderStatus, TParcelEntity } from 'src/shared/api/queries/ordersApi';

export const PARCEL_DEFAULT_VALUES: TParcelEntity = {
  dimensions: {
    weight: '',
  },
  dispatch_number: '',
};

export const FORM_AVAILABLE = {
  noDimensions: [
    EOrderStatus.notPaid,
    EOrderStatus.paid,
    EOrderStatus.inWork,
    EOrderStatus.redeemed,
    EOrderStatus.inStock,
  ],
  track: [EOrderStatus.courierWaiting, EOrderStatus.deliveryCreated],
  delivery: [EOrderStatus.delivery],
  addParcel: [EOrderStatus.assembly, EOrderStatus.deliveryCreated, EOrderStatus.courierWaiting],
  changeDelivery: [
    EOrderStatus.notPaid,
    EOrderStatus.paid,
    EOrderStatus.inWork,
    EOrderStatus.purchased,
    EOrderStatus.shipping,
  ],
};

export const validationSchema: Record<string, Rule> = {
  dimensions: {
    message: 'Введите значение больше 0',
    validator: (_, value) => {
      if (!String(value) || value > 0) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
  },
};
