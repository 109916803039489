import { TSimpleOptionObject } from 'src/utils';
import { DetailedOrder } from 'src/pages/orders/order/types';
import { TAddressEntity, TDeliveryEntity, TUpdateOrderBody } from 'src/shared/api/queries/ordersApi';
import { TAddress } from 'src/shared/api/queries/addressApi';

import { TFormValues } from './Measurements.types';

export const defaultFormValues = (data?: DetailedOrder['order']): TFormValues => ({
  recipient: {
    ...data?.recipient,
    address: data?.address?.full,
  },
  delivery: data?.delivery,
  parcels: data?.parcels,
});

export const mapProviders = (tariffs: TDeliveryEntity[]) => {
  const providers: Record<number, TSimpleOptionObject<number>> = {};

  tariffs.forEach(i => {
    if (!providers[i.provID]) {
      providers[i.provID] = {
        value: i.provID,
        label: i.provName,
      };
    }
  });

  return Object.keys(providers).map(i => providers[Number(i)]);
};

export const mapFormValues = ({
  defaultDelivery,
  newDelivery,
  values,
  recipientAddress,
}: {
  values: TFormValues;
  defaultDelivery: TDeliveryEntity;
  newDelivery?: TDeliveryEntity;
  recipientAddress: TAddress;
}): TUpdateOrderBody['data'] => {
  const { recipient } = values!;
  const { address, ...restRecipient } = recipient!;

  return {
    dimensions: values!.parcels?.[0]?.dimensions,
    adminDeliveryEditRequest: {
      delivery: newDelivery || defaultDelivery,
      parcels: values.parcels!,
      recipient: {
        ...restRecipient,
        tariffId: (newDelivery || defaultDelivery).tariff_id,
      },
      recipient_address: {
        ...recipientAddress,
        index: recipientAddress.index || recipientAddress.zip,
      },
    },
  };
};

export const addressNormalize = (data: TAddressEntity): TAddress => ({
  ...data,
  index: data.index || data.zip,
});
