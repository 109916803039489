import { Card } from 'antd';
import { User } from 'src/pages/employees/types';

import { roleOptions } from '../../../config';
import styles from './EmployeesInfo.module.less';

interface EmployeesInfoProps {
  data: User;
}

export const EmployeesInfo = ({ data }: EmployeesInfoProps) => (
  <Card className={styles.card}>
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.name}>{data.name}</h2>

        <div className={styles.item}>
          <span>Email:</span>
          <div className={styles.data}>{data.email}</div>
        </div>

        <div className={styles.item}>
          <span>Роль:</span>
          <div className={styles.data}>{roleOptions[data.role as keyof typeof roleOptions]}</div>
        </div>
      </div>
    </div>
  </Card>
);
