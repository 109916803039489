import { Layout } from 'antd';
import { useEffect } from 'react';
import { useIsAuth } from 'src/shared/api/model';

import { HeaderProfile } from './ui/profile';
import { HeaderSettings } from './ui/settings';
import { getProfileEvent, useProfile } from './model';
import styles from './Header.module.less';

const { Header } = Layout;

export const HeaderComponent = () => {
  const profile = useProfile();
  const isAuth = useIsAuth();
  useEffect(() => {
    if (!profile && isAuth) {
      getProfileEvent();
    }
  }, [isAuth, profile]);

  if (!isAuth) {
    return null;
  }

  return (
    <Header className={styles.Header}>
      <div className={styles.HeaderLogo}>AllAll.ru</div>

      <div className={styles.HeaderRightContainer}>
        <HeaderProfile {...profile} />
        <HeaderSettings />
      </div>
    </Header>
  );
};
