import { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { addressApi } from 'src/shared/api';
import { TAddress } from 'src/shared/api/queries/addressApi';

export const useAddress = () => {
  const [addressOptions, setAddressOptions] = useState<DefaultOptionType[]>([]);
  const [allAddreses, setAllAddresses] = useState<TAddress[]>([]);

  const fetchAddress = async (value?: string) => {
    if (!value) {
      return;
    }

    const response = await addressApi.searchDeliveryAddress({
      query: value,
    });

    setAllAddresses(response.items);
    setAddressOptions(
      response.items.map(i => ({
        label: i.full,
        value: i.full,
      })),
    );
  };

  return {
    addressOptions,
    fetchAddress,
    allAddreses,
  };
};
