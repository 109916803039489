import { createEffect, createEvent, createStore, sample, combine } from 'effector-logger';
import { chatMessagesApi } from 'src/shared/api';
import { setError } from 'src/features/notifications';
import { ChatMessagesResponseData, ChatRequest } from 'src/shared/api/queries/chatMessagesApi';
import { defaultInitState } from 'src/shared/config';
import { Message } from 'src/shared/types';

import { initMessagesState } from './config';

export const $currentChatId = createStore<number | null>(null);
const $oldMessagesReqData = combine({ chatId: $currentChatId, data: defaultInitState });
export const selectChatId = createEvent<number>();
$currentChatId.on(selectChatId, (_, data) => data);

export const $oldMessages = createStore<ChatMessagesResponseData | null>(initMessagesState);
export const resetOldMessagesEvent = createEvent();
export const getMessagesByScrollEvent = createEvent<ChatRequest>();
export const getWSMessagesEvent = createEvent<Message>();

export const getOldMessagesFx = createEffect(chatMessagesApi.getOldMessages);
export const getMessagesByScrollFx = createEffect(chatMessagesApi.getOldMessages);

$oldMessages
  .on(getOldMessagesFx.doneData, (_, data) => ({ ...data, items: data.items }))
  .on(getMessagesByScrollFx.doneData, (state, data) => {
    if (!state) return data;
    const resultItems = state?.items || [];
    return {
      ...data,
      items: [...resultItems, ...data.items],
    };
  })
  .on(getWSMessagesEvent, (state, data) => {
    if (!state) return { ...initMessagesState, items: [data] };
    const resultItems = state?.items || [];
    return { ...state, items: [...resultItems, data] };
  })
  .reset(resetOldMessagesEvent);

sample({
  clock: $oldMessagesReqData,
  target: getOldMessagesFx,
});

sample({
  clock: getMessagesByScrollEvent, // ???
  target: getMessagesByScrollFx,
});

sample({
  clock: getOldMessagesFx.failData,
  fn: ({ message }) => message,
  target: setError,
});
