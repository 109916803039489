import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Form, Input, Button, Checkbox, TreeSelect, AutoComplete } from 'antd';
import { Hooks } from 'src/shared/lib';
import { Title } from 'src/shared/ui';
import { DefaultOptionType } from 'antd/lib/select';
import { treeSelectFieldNames, defaultInitState } from 'src/shared/config';
import styles from './UpdateSource.module.less';
import { DetailedSource } from './types';
import {
  $categories,
  $pavilionsOptions,
  initAddSourcePage,
  initPagePavilions,
  getCategoriesFx,
  submitUpdateSource,
  initFlag,
} from './model';

export interface FormSuccess {
  url: string;
  category_id: number;
  is_active: boolean;
}

interface UpdateSourceProps {
  closeHandler: () => void;
  id?: string;
  activeSource: DetailedSource | null;
  supPavilions?: Record<number, { pavilion: { name: string; id: number } }>;
}

export const UpdateSource = ({ closeHandler, id, activeSource, supPavilions }: UpdateSourceProps) => {
  const [form] = Form.useForm();
  const [selectedPavId, setSelectedPavId] = useState('');
  const categories = useStore($categories);
  const pavilionsOptions = useStore($pavilionsOptions);
  const isCategoriesLoading = useStore(getCategoriesFx.pending);

  const activePavName =
    supPavilions && activeSource
      ? supPavilions[activeSource?.supplier_pavilion_id]?.pavilion?.name
      : activeSource?.pavilion?.name;

  const activePavId =
    supPavilions && activeSource
      ? supPavilions[activeSource?.supplier_pavilion_id]?.pavilion?.id
      : activeSource?.pavilion?.id;

  useEffect(() => {
    initAddSourcePage();
    initPagePavilions(defaultInitState);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      url: activeSource?.url,
      supplier_pavilion_id: activePavName,
      category_id: activeSource?.category_id,
      is_active: activeSource?.is_active,
    });
    const pavId = activePavId ? activePavId.toString() : '';
    setSelectedPavId(pavId);
    initFlag();
  }, [form, activeSource, activePavName]);

  const formSubmitHandler = (values: FormSuccess) => {
    const resultId = id && supPavilions ? id : activeSource?.supplier_id;

    if (resultId) {
      submitUpdateSource({
        id: activeSource?.id || '',
        data: { ...values, supplier_id: Number(resultId), pavilion_id: Number(selectedPavId) },
      });
    }
    closeHandler();
  };

  const handleCancelClose = () => {
    form.resetFields();
    closeHandler();
  };

  const handleSelect = (value: string, options: DefaultOptionType) => setSelectedPavId(options.id);

  const handleSearch = (value: string) => {
    const reqData = value ? { ...defaultInitState, query: value } : { ...defaultInitState };
    initPagePavilions(reqData);
  };

  const activity = Hooks.useCheckActivity(activeSource?.is_active ? 'Активный' : 'Неактивный');

  return (
    <div>
      <Title>Редактирование источника</Title>
      <Form
        className={styles.updateSource}
        form={form}
        name="basic"
        initialValues={{
          url: activeSource?.url,
          category_id: activeSource?.category_id,
          is_active: activeSource?.is_active,
          supplier_pavilion_id: activePavName,
        }}
        layout="vertical"
        onFinish={formSubmitHandler}
        autoComplete="off">
        <Form.Item
          label="Ссылка на источник"
          name="url"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, добавьте ссылку на источник!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Павильон"
          name="supplier_pavilion_id"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите павильон!',
            },
          ]}>
          <AutoComplete
            options={pavilionsOptions}
            onSelect={handleSelect}
            onChange={handleSearch}
            placeholder="Поиск по павильонам"
          />
        </Form.Item>
        <Form.Item
          label="Категория"
          name="category_id"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите категорию!',
            },
          ]}>
          <TreeSelect
            placement="topRight"
            disabled={isCategoriesLoading}
            style={{
              width: '100%',
            }}
            fieldNames={treeSelectFieldNames}
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
            }}
            treeData={categories?.items}
          />
        </Form.Item>
        <Form.Item label="Активность источника" name="is_active" valuePropName="checked">
          <Checkbox onChange={activity.onChange}>{activity.value}</Checkbox>
        </Form.Item>

        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType="submit" className={styles.button} type="primary">
              Готово
            </Button>
          </Form.Item>

          <Button onClick={handleCancelClose} htmlType="button" className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </div>
  );
};
