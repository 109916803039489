import { Col, Row, Button, Form, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import styles from './AddProductOptions.module.less';

const { Option } = Select;

interface IAddProductOptionsProps {
  prev: () => void;
}

export const AddProductOptions = ({ prev }: IAddProductOptionsProps) => {
  const [form] = Form.useForm();

  return (
    <article className={styles.AddProductOptions}>
      <h3>Опции</h3>

      <Form
        form={form}
        layout="vertical"
        onFinish={() => { }}
      >
        <Form.List name="options">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key}>
                  <Row>
                    <Col span={23}>
                      <div>
                        <Form.Item
                          {...restField}
                          name={[name, 'type']}
                          label="Тип товара"
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <Select
                            mode="tags"
                            allowClear
                            placeholder="Тип товара"
                            onChange={() => { }}
                          >
                            <Option key="1">Цвет</Option>
                            <Option key="2">Размер</Option>
                            <Option key="3">Модель</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={1}>
                      <div className={styles.AddProductOptionsRemove}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div>
                        <Form.Item
                          {...restField}
                          name={[name, 'color']}
                          label="Цвет товара"
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <Select
                            mode="tags"
                            allowClear
                            placeholder="Цвет товара"
                            onSelect={() => { }} // Один выбор
                          >
                            <Option key="1">Option 1</Option>
                            <Option key="2">Option 2</Option>
                            <Option key="3">Option 3</Option>
                            <Option key="4">Option 4</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div>
                        <Form.Item
                          {...restField}
                          name={[name, 'size']}
                          label="Размер товара"
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <Select
                            mode="tags"
                            allowClear
                            placeholder="Размер товара"
                            onChange={() => { }}
                          >
                            <Option key="1">Option 1</Option>
                            <Option key="2">Option 2</Option>
                            <Option key="3">Option 3</Option>
                            <Option key="4">Option 4</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => add()}
                >Добавить опцию</Button>
              </Form.Item>
            </>
          )}
        </Form.List>


        <div className={styles.AddProductOptionsBtn}>
          <Button
            onClick={prev}
          >Назад</Button>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                // htmlType="submit"
                type="primary"
              // disabled={
              //   !form.isFieldsTouched(true) ||
              //   !!form.getFieldsError().filter(({ errors }) => errors.length).length
              // }
              >Опубликовать</Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </article>
  )
};



