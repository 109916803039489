import { createEffect, createEvent, createStore, sample } from 'effector';
import { Storage } from 'src/shared/lib';
import { ordersApi } from 'src/shared/api';
import { Paginated , SelectOptions } from 'src/shared/types';
import { OrderTaskStatuses } from 'src/pages/orders/ordersList/types';

import { initColumnSettings, initState } from './config';
import { Order } from './types';


export const $orders = createStore<Paginated<Order> | null>(null);
export const $ordersStatusOptions = createStore<SelectOptions>([]);
export const $requestData = createStore<ordersApi.OrdersRequestData>(initState);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'profile-orders', key: 'tableSettings' });

export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const initLatestOrdersPage = createEvent<ordersApi.OrdersRequestData>();
export const submitChangeRequestData = createEvent<ordersApi.OrdersRequestData>();
export const initPage = createEvent();

export const getOrdersFx = createEffect(ordersApi.getOrdersList);
export const getOrderStatusesFx = createEffect(ordersApi.getOrderStatuses);

$orders.on(getOrdersFx.doneData, (_, data) => data);
$requestData.on(submitChangeRequestData, (_, data) => data);
$ordersStatusOptions.on(getOrderStatusesFx.doneData, (_, data: OrderTaskStatuses) => {
  const statusOptions =
    data?.items.map(item => ({
      value: item?.status || item?.status === 0 ? item.status.toString() : '',
      label: item?.status_name || '',
    })) || [];
  return statusOptions;
});

sample({
  clock: initPage,
  target: getOrderStatusesFx,
});

sample({
  clock: initLatestOrdersPage,
  target: getOrdersFx,
});

sample({
  clock: submitChangeRequestData,
  source: $requestData,
  target: getOrdersFx,
});

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});
