import React from 'react';
import { Button, Form } from 'antd';

import { useMeasureMents } from '../../hooks/useMeasureMents';
import { ParcelsItem } from './components';
import styles from '../../Measurements.module.less';
import { FORM_AVAILABLE } from '../../Measurements.constants';

export const ParcelsForm: React.FC = () => {
  const { order } = useMeasureMents();

  return (
    <div className={styles.group}>
      <Form.List name='parcels'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <ParcelsItem name={name} key={`parcel-${key}`} onRemove={() => remove(name)} {...restField} />
            ))}
            <Button onClick={() => add()} disabled={!FORM_AVAILABLE.addParcel.includes(order!.order.status)}>
              Добавить еще место
            </Button>
          </>
        )}
      </Form.List>
    </div>
  );
};
