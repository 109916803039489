import { Menu, Avatar } from 'antd';

import { logOut } from '../../../../shared/api/model';
import styles from './HeaderProfile.module.less';

const { SubMenu } = Menu;

interface IHeaderProfileProps {
  name?: string;
  avatar?: string;
}

export const HeaderProfile = ({ name, avatar }: IHeaderProfileProps) => (
  <Menu key="headerProfile" mode="horizontal" disabledOverflow>
    <SubMenu
      key="profile"
      title={
        <>
          <Avatar className={styles.avatar} src={avatar} />
          <span>{name}</span>
        </>
      }>
      <Menu.Item key="exit" onClick={() => logOut()}>
        Выйти
      </Menu.Item>
    </SubMenu>
  </Menu>
);
