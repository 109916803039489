import { Navigate } from 'react-router-dom';
import { useIsAuth } from 'src/shared/api/model';
import { AuthForm } from 'src/features/authForm/ui';
import { Card } from 'antd';

import styles from './Login.module.less';

export const LoginPage = () => {
  const isAuth = useIsAuth();

  if (isAuth) {
    return <Navigate to="/orders" />;
  }
  return (
    <section className={styles.loginPage}>
      <Card className={styles.card}>
        <AuthForm />
      </Card>
    </section>
  );
};
