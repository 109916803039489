import api from '../api';

export interface getAddReqData {
  client_id: number | string;
}

export interface AddAddressReqData {
  name: string;
  locality: string;
  street_house_flat: string;
  full: string;
  fias: string;
  kladr: string;
  index: string;
  client_id?: number | string;
}

export interface UpdateAddressReqData {
  id: string | number;
  data: AddAddressReqData;
}

export type TSimpleAddress = {
  street: string;
  full: string;
  city: string;
  country: string;
  locality: string;
  zip: string;
};

export type TFullAddress = {
  id: number;
  name: string;
  locality: string;
  street_house_flat: string;
  full: string;
  index: string;
  client_id: number;
  fias: string;
  kladr: string;
};

export type TAddress = {
  full: string;
  city: string;
  street: string;
  locality: string;
  country?: string;
  zip?: string;
  street_house_flat: string;
  fias: string;
  kladr: string;
  index?: string;
}

type TSearchDeliveriAddressBody = {
  query?: string;
  kladr?: string;
  specify?: boolean;
};

type TSearchDeliveriAddressResponse = {
  items: TAddress[];
};

export const getAddresses = (data: getAddReqData) => api.post('/addresses', data).then(response => response.data);

export const addAddress = (data: AddAddressReqData) => api.post('/address', data).then(response => response.data);

export const updateAddress = (data: UpdateAddressReqData) =>
  api.put(`/address/${data.id}`, data.data).then(response => response.data);

export const removeAddress = (id: string | number) => api.delete(`/address/${id}`).then(response => response.data);

export const searchDeliveryAddress = (data: TSearchDeliveriAddressBody): Promise<TSearchDeliveriAddressResponse> =>
  api.post('/address/autocomplete', data).then(response => response.data);
