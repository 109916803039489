import api from '../api';

export interface UsersRequestData {
  name?: string;
  email?: string;
  phone?: string;
  created_at?: Date;
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface UpdatedUserRequestData {
  id: number | string;
  data: {
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    password?: string;
  };
}

export interface AddUserRequestData {
  name: string;
  email: string;
  phone: string;
  role: string;
  password?: string;
  pass?: string;
  pass_date?: Date;
}

type TUserOrdersSearchBody = {
  orderId: number;
  clientId: number;
  search?: string;
};

export type TUserOrderEntity = {
  id: number;
  code: string;
  status_name: string;
  status_description: string;
  parent_id: number;
};

type TUserOrdersSearchResponse = {
  items: TUserOrderEntity[]
}

export const getUsersList = (data: UsersRequestData) => api.post('/users', data).then(response => response.data);

export const updateUser = (data: UpdatedUserRequestData) =>
  api.put(`/user/${data.id}`, data.data).then(response => response.data);

export const addUser = (data: AddUserRequestData) => api.post('/user', data).then(response => response.data);

export const getUser = (id: string) => api.post(`/user/${id}`).then(response => response.data);

export const ordersSearch = (data: TUserOrdersSearchBody): Promise<TUserOrdersSearchResponse> =>
  api.post(`/user/orders/search`, data).then(response => response.data);
