import { documentsCodes } from 'src/pages/settings';

import api from '../api';

interface CreatingDocument {
  name: string;
  code: keyof typeof documentsCodes | null;
  published: boolean;
  document?: string;
  parent_id?: number;
}

export interface EditingCategory extends CreatingDocument {
  id: number;
}

interface GetDocsParams {
  search?: string;
  parent_id?: number;
}

export const getDocuments = (data?: GetDocsParams) => api.post('/documents', data).then(response => response.data);
export const getDocument = (id: number) => api.get(`/document/${id}`).then(response => response.data);
export const createDocument = (data: CreatingDocument) => api.post(`/document`, data).then(response => response.data);
export const editDocument = (data: EditingCategory) =>
  api.put(`/document/${data.id}`, data).then(response => response.data);
