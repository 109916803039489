import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Dropdown, Table, Spin, Empty, Button } from 'antd';
import { MainModal } from 'src/shared/ui/mainModal';
import { MainButton } from 'src/shared/ui/mainButton';
import { Title } from 'src/shared/ui';

import { $tokens, $redirectLink, getTokensFx, addTokenFx, getTokensLinkFx, removeTokenFx } from './model';
import styles from './Tokens.module.less';

export interface IRedirectLink {
  url: string;
}

export interface ITokens {
  items: {
    id: number;
    value: string;
  }[];
}

export const Tokens = () => {
  const [removedTokeId, setRemovedTokenId] = useState(0);
  const [modalState, setModalState] = useState<{
    isAdd: boolean;
    isUpdate: boolean;
    isRemove: boolean;
  }>({
    isAdd: false,
    isUpdate: false,
    isRemove: false,
  });

  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const redirectLink = useStore<IRedirectLink>($redirectLink);
  const tokens = useStore<ITokens>($tokens);
  const isLoading = useStore(getTokensFx.pending);

  useEffect(() => {
    const { code, state, 'device_id': deviceId } = Object.fromEntries(new URLSearchParams(location.search));
    if (code) {
      addTokenFx({
        code,
        state,
        device_id: deviceId
      });
      setSearchParams('');
    }

    getTokensFx();
  }, []);

  const handleAddLink = () => {
    if (global?.window) {
      getTokensLinkFx({ redirect_url: global?.window.location.href });
    }
  };

  const handlerRemoveToken = () => {
    removeTokenFx(removedTokeId);
    setModalState({ ...modalState, isRemove: false });
  };

  const handleCloseRemove = (): void => {
    setModalState({ ...modalState, isRemove: false });
  };

  const handleDropdownMenu = (id: number): void => {
    setRemovedTokenId(id);
    setModalState({ ...modalState, isRemove: true });
  };

  if (redirectLink?.url) {
    global?.window.location.replace(redirectLink.url);
  }

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.tokens}>
      <Card className={styles.card}>
        <div className={styles.wrapper}>
          <Title className={styles.title}>Токены</Title>
          <div className={styles.buttonAdd}>
            <Button type="primary" className={styles.button} onClick={handleAddLink}>
              Получить
            </Button>
          </div>
        </div>
        <Table
          dataSource={tokens?.items || []}
          className={styles.table}
          rowKey={record => record.id}
          loading={isLoading}
          bordered
          columns={[
            {
              title: 'Токен',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Значение',
              className: 'value',
              dataIndex: 'value',
              key: 'value',
            },
            {
              title: '',
              key: 'settings',
              render: (_, { id }) => (
                <Dropdown
                  overlayClassName={styles.menu}
                  placement="bottom"
                  menu={{
                    items: [
                      {
                        key: 'remove',
                        label: 'Удалить',
                        onClick: () => handleDropdownMenu(id),
                      },
                    ],
                  }}>
                  <MoreOutlined />
                </Dropdown>
              ),
              width: '3%',
            },
          ]}
          pagination={{
            showSizeChanger: true,
          }}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
        />
      </Card>

      <MainModal isOpen={modalState.isRemove} closeHandler={handleCloseRemove}>
        <div className={styles.remove}>
          <h2 className={styles.removeTitle}>Вы уверены, что хотите удалить токен?</h2>
          <div className={styles.buttons}>
            <MainButton className={styles.button} onClick={handlerRemoveToken} type="primary">
              Удалить
            </MainButton>
            <MainButton className={styles.button} onClick={handleCloseRemove}>
              Отменить
            </MainButton>
          </div>
        </div>
      </MainModal>
    </div>
  );
};
