import React, { useState } from 'react';
import { Form, Button, Spin } from 'antd';

import styles from '../../Measurements.module.less';
import { useMeasureMents } from '../../hooks/useMeasureMents';
import { TFormValues } from '../../Measurements.types';
import { RecipientForm } from '../RecipientForm';
import { DeliveryForm } from '../DeliveryForm';
import { ParcelsForm } from '../ParcelsForm';
import { FORM_AVAILABLE } from '../../Measurements.constants';

export const Content: React.FC = () => {
  const { closeHandler, isLoading, order, form, defaultValues, fetchUpdate, onCloseForm } = useMeasureMents();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values: TFormValues) => {
    setLoading(true);
    fetchUpdate?.({
      values,
      callback: () => {
        setLoading(false);
      },
      onSuccess: () => {
        closeHandler?.();
      }
    });
  };

  if (!form) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <div className={styles.measurements}>
      {order?.order ? (
        <>
          <div className={styles.head}>
            <h2 className={styles.title}>Изменение доставки</h2>
            <p className={styles.groupTitle}>
              {order.order?.delivery_number || order.order?.dispatch_number
                ? `${order.order?.delivery_number || '-'} / ${order.order?.dispatch_number || '-'}`
                : null}
            </p>
          </div>
          <Form<TFormValues>
            form={form}
            name='basic'
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={defaultValues}
            onFinish={onSubmit}
            autoComplete='off'
            disabled={loading}
          >
            {!FORM_AVAILABLE.noDimensions.includes(order.order.status) && <ParcelsForm />}
            <RecipientForm />
            <DeliveryForm />
            <div className={styles.buttons}>
              <Form.Item>
                <Button
                  htmlType='submit'
                  className={styles.button}
                  type='primary'
                  disabled={loading}
                  loading={loading}
                >
                  Сохранить
                </Button>
              </Form.Item>
              <Button onClick={onCloseForm} className={styles.button} disabled={loading}>
                Отмена
              </Button>
            </div>
          </Form>
        </>
      ) : null}
    </div>
  );
};
