import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { ordersApi, clientsApi } from 'src/shared/api';
import { mapSeries , Storage } from 'src/shared/lib';
import { OrdersRequestData } from 'src/shared/api/queries/ordersApi';
import { ClientsRequestData } from 'src/shared/api/queries/clientsApi';
import { setError, setSuccess } from 'src/features/notifications';
import { initState as clientsInitState } from 'src/pages/profiles/clients/config';
import { Paginated , SelectOptions } from 'src/shared/types';
import { Client } from 'src/pages/profiles/clients/types';

import { initState, initColumnSettings, PER_PAGE, debitResponseStatuses, getDebitMessage } from './config';
import { DetailedOrder } from '../order/types';
import { IncomeOrders, IOrder, Label, OrderTaskStatuses, CourierDates } from './types';

export const $orders = createStore<Paginated<IOrder> | null>(null);
export const $order = createStore<DetailedOrder | null>(null);
export const $activeOrderId = createStore<number | string>('');
export const $ordersStatusOptions = createStore<SelectOptions>([]);
export const $detailedOrdersForOpen = createStore<DetailedOrder[]>([]);
export const $detailedOrdersForPrint = createStore<DetailedOrder[] | any[]>([]);
export const $clients = createStore<Paginated<Client> | null>(null);
export const $requestData = createStore<OrdersRequestData>(initState);
export const $clientsData = createStore<ClientsRequestData>(clientsInitState);
export const $labelsForPrint = createStore<Label | null>(null);
export const $courierDates = createStore<CourierDates | null>(null);
export const $visibleColumns = createStore(initColumnSettings);
Storage.persist.entity($visibleColumns, { slice: 'orders', key: 'tableSettings' });
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'orders', key: 'pageSize' });

export const changeRequestData = createEvent<OrdersRequestData>();
export const changeClientsData = createEvent<ClientsRequestData>();
export const getOrdersData = createEvent<OrdersRequestData>();
export const viewAssemblyTasks = createEvent<IOrder[]>();
export const printAssemblyTasks = createEvent<IOrder[]>();
export const moveOrderToAssembly = createEvent<IOrder[]>();
export const clearViewedTasks = createEvent();
export const clearPrintedTasks = createEvent();
export const submitPrintLabelData = createEvent<ordersApi.LabelRequestData>();
export const refetchOrdersList = createEvent();
export const submitPrintLabelsData = createEvent<ordersApi.LabelsRequestData>();
export const clearLabelsData = createEvent();
export const askForDelivery = createEvent<number>();
export const submitSendToDelivery = createEvent<number[]>();
export const submitDates = createEvent<CourierDates>();
export const submitCheckStatuses = createEvent<number[]>();
export const submitRetryDebitData = createEvent<number>();
export const changeVisibleColumns = createEvent<Record<string, boolean>>();
export const changePageSize = createEvent<number>();
export const initPage = createEvent();
export const submitOrderReqData = createEvent<number>();
export const submitOrderMeasureReqData = createEvent<ordersApi.TUpdateOrderBody>();

export const getOrdersFx = createEffect(ordersApi.getOrdersList);
export const getOrderFx = createEffect<number, DetailedOrder, AxiosError<{ message: string }>>(ordersApi.getOrder);
export const postOrderMeasureFx = createEffect<
  ordersApi.TUpdateOrderBody,
  DetailedOrder,
  AxiosError<{ message: string }>
>(ordersApi.updateOrder);
export const getOrderStatusesFx = createEffect(ordersApi.getOrderStatuses);
export const getClientsFx = createEffect(clientsApi.getClientsList);
export const createDeliveryFx = createEffect(ordersApi.createDelivery);
export const checkDeliveryFx = createEffect(ordersApi.sendToDelivery);
export const takeDeliveryFx = createEffect(ordersApi.takeDelivery);
export const callCourierFx = createEffect(ordersApi.callCourier);
export const retryDebitFx = createEffect(ordersApi.retryDebit);

export const getAssemblyTaskFx = createEffect((values: IOrder[]) =>
  mapSeries<DetailedOrder>(
    values.map((order: IOrder) => order.id.toString()),
    ordersApi.getAssemblyLabel,
  ),
);

export const assemblyOrderFx = createEffect((values: IOrder[]) =>
  mapSeries<DetailedOrder>(
    values.map((order: IOrder) => order.id.toString()),
    ordersApi.moveOrderToAssembly,
  ),
);

export const getAssemblyLabelFx = createEffect((values: IOrder[]) =>
  mapSeries<DetailedOrder>(
    values.map((order: IOrder) => order.id.toString()),
    ordersApi.getAssemblyLabel,
  ),
);

export const getLabelFx = createEffect<ordersApi.LabelRequestData, Label, AxiosError<{ message: string }>>(
  ordersApi.getOrdersLabel,
);

export const getLabelsFx = createEffect<ordersApi.LabelsRequestData, Label, AxiosError<{ message: string }>>(
  ordersApi.getOrdersLabels,
);

$requestData.on(changeRequestData, (_, data) => data);
$orders.on(getOrdersFx.doneData, (_, data: IncomeOrders) => ({
  pagination: data.pagination,
  items: data.items.map(order => ({ ...order, client: data.clients[order.client_id] })),
}));

$order.on(getOrderFx.doneData, (_, data) => data).reset(createDeliveryFx.doneData);
$activeOrderId.on(submitOrderReqData, (_, data) => data).reset(createDeliveryFx.doneData);

$clientsData.on(changeClientsData, (_, data) => data);

$clients.on(getClientsFx.doneData, (_, data) => data);

$detailedOrdersForOpen.on(clearViewedTasks, () => []).on(getAssemblyTaskFx.doneData, (_, data) => data);
$detailedOrdersForPrint.on(clearPrintedTasks, () => []).on(getAssemblyLabelFx.doneData, (_, data) => data);
$courierDates.on(submitDates, (_, data) => data);
$ordersStatusOptions.on(getOrderStatusesFx.doneData, (_, data: OrderTaskStatuses) => {
  const statusOptions =
    data?.items.map(item => ({
      value: item?.status || item?.status === 0 ? item.status.toString() : '',
      label: item?.status_name || '',
    })) || [];
  return statusOptions;
});

$labelsForPrint
  .on(getLabelFx.doneData, (_, data) => data)
  .on(getLabelsFx.doneData, (_, data) => data)
  .reset(clearLabelsData);

// get order's data

sample({
  clock: submitOrderReqData,
  target: getOrderFx,
});

sample({
  clock: getOrderFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при получении данных о заказе',
  target: setError,
});

// submit order's measurement data

sample({
  clock: submitOrderMeasureReqData,
  target: postOrderMeasureFx,
});

sample({
  clock: postOrderMeasureFx.doneData,
  fn: () => 'Габариты заказа успешно обновлены',
  target: setSuccess,
});

sample({
  clock: postOrderMeasureFx.doneData,
  source: $activeOrderId,
  target: createDeliveryFx,
});

sample({
  clock: postOrderMeasureFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при обновлении габаритов заказа',
  target: setError,
});

// get orders statuses

sample({
  clock: initPage,
  target: getOrderStatusesFx,
});

// change page size

sample({
  clock: changePageSize,
  target: $pageSize,
});

//  change columns

sample({
  clock: changeVisibleColumns,
  target: $visibleColumns,
});

// view orders

sample({
  clock: viewAssemblyTasks,
  target: getAssemblyTaskFx,
});

sample({
  clock: getAssemblyTaskFx.failData,
  fn: () => 'Ошибка загрузки заказов',
  target: setError,
});

//  move to assembly

sample({
  clock: moveOrderToAssembly,
  target: assemblyOrderFx,
});

// print orders

sample({
  clock: printAssemblyTasks,
  target: getAssemblyLabelFx,
});

sample({
  clock: assemblyOrderFx.failData,
  fn: () => 'Ошибка перевода заказов в сборку',
  target: setError,
});

sample({
  clock: [
    clearPrintedTasks,
    createDeliveryFx.done,
    retryDebitFx.done,
    callCourierFx.done,
    checkDeliveryFx.doneData,
    assemblyOrderFx.doneData,
  ],
  source: $requestData,
  fn: data => ({ ...data, page: 1 }),
  target: getOrdersFx,
});

// main model

sample({
  clock: getOrdersData,
  target: getOrdersFx,
});

sample({
  clock: [changeRequestData, refetchOrdersList],
  source: $requestData,
  target: getOrdersFx,
});

sample({
  clock: changeClientsData,
  source: $clientsData,
  target: getClientsFx,
});

// print labels

sample({
  clock: submitPrintLabelData,
  target: getLabelFx,
});

sample({
  clock: submitPrintLabelsData,
  target: getLabelsFx,
});

sample({
  clock: [getLabelFx.failData, getLabelsFx.failData],
  fn: data => data.response?.data?.message || 'Ошибка при получении файла',
  target: setError,
});

// create delivery

sample({
  clock: askForDelivery,
  target: createDeliveryFx,
});

sample({
  clock: createDeliveryFx.doneData,
  fn: () => 'Доставка оформлена',
  target: setSuccess,
});

sample({
  clock: createDeliveryFx.failData,
  fn: () => 'Ошибка при оформлении доставки',
  target: setError,
});

// check send to delivery

sample({
  clock: submitCheckStatuses,
  target: checkDeliveryFx,
});

sample({
  clock: checkDeliveryFx.doneData,
  fn: () => 'Статусы заказов обновлены',
  target: setSuccess,
});

sample({
  clock: checkDeliveryFx.failData,
  fn: () => 'Ошибка при передаче заказов',
  target: setError,
});

// take delivery

sample({
  clock: submitSendToDelivery,
  target: takeDeliveryFx,
});

sample({
  clock: takeDeliveryFx.doneData,
  source: $courierDates,
  filter: src => !!src,
  target: callCourierFx,
});

sample({
  clock: takeDeliveryFx.failData,
  fn: () => 'Ошибка при уведомлении службы доставки о заказах',
  target: setError,
});

sample({
  clock: callCourierFx.doneData,
  fn: () => 'Курьер вызван',
  target: setSuccess,
});

sample({
  clock: callCourierFx.failData,
  fn: () => 'Ошибка при вызове курьера',
  target: setError,
});

// retry debit

sample({
  clock: submitRetryDebitData,
  target: retryDebitFx,
});

sample({
  clock: retryDebitFx.doneData,
  filter: data => data?.status === debitResponseStatuses.debitSuccessStatus,
  fn: () => 'Средства успешно списаны',
  target: setSuccess,
});

sample({
  clock: retryDebitFx.doneData,
  filter: data => debitResponseStatuses.debitFailStatuses.includes(data?.status),
  fn: data => getDebitMessage(data?.status),
  target: setError,
});

sample({
  clock: retryDebitFx.failData,
  fn: () => 'Ошибка при попытке списания средств',
  target: setError,
});
