import React from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';

import styles from './MainModal.module.less';

interface IMainModalProps {
  width?: string | number;
  isOpen: boolean;
  closeHandler: () => void;
  children: React.ReactNode;
  closeIconFixed?: boolean;
  title?: string;
  subTitle?: string;
  isCloseInside?: boolean;
}

export const MainModal = ({
  width = '520px',
  isOpen,
  closeHandler,
  children,
  closeIconFixed,
  title,
  subTitle,
  isCloseInside,
  ...rest
}: IMainModalProps) => (
  <Modal
    className={styles.modal}
    closeIcon={
      <CloseOutlined
        size={34}
        className={classNames([styles.close, closeIconFixed && styles.fixed, isCloseInside && styles.inside])}
      />
    }
    width={width}
    title=""
    centered
    footer={null}
    open={isOpen}
    onOk={closeHandler}
    onCancel={closeHandler}
    {...rest}>
    {title && (
      <div className={styles.head}>
        <h2 className={styles.title}>{title}</h2>
        <span className={styles.subTitle}>{subTitle}</span>
      </div>
    )}
    {children}
  </Modal>
);
