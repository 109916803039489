import { createStore, createEvent, createEffect, sample } from 'effector';
import { chatsApi } from 'src/shared/api';

export const $chats = createStore<any>([]);
export const $subData = createStore<any>(null);

export const submitAmountData = createEvent<string>();
export const getTestWSMessagesEvent = createEvent<any>();

// const getLabelsFx = createEffect(({ orders, type }: selectedLabelsReqData) =>
//   Promise.all(orders.map((order: Order) => ordersApi.getOrdersLabel({ id: order.id, type }))),
// );

export const getChatsDataFx = createEffect((value: string) =>
  Promise.all([...Array(Number(value))].map(() => chatsApi.getTestChat())),
);

sample({
  clock: submitAmountData,
  target: getChatsDataFx,
});

$chats.on(getChatsDataFx.doneData, (_, data) => data);

$subData.on(getTestWSMessagesEvent, (state, data) => {
  const resultItems = state || [];
  return { ...resultItems, data };
});

// export const $captchaSettings = createStore<resultCaptcha | null>(null);

// export const initCaptchaPage = createEvent();
// export const submitUpdateSettingsData = createEvent<commissionApi.UpdateSettingsReqData>();

// export const getSettingsFx = createEffect(commissionApi.getSettings);
// export const updateSettingsFx = createEffect<
//   commissionApi.UpdateSettingsReqData,
//   null,
//   AxiosError<{ message: string }>
// >(commissionApi.updateSettings);

// sample({
//   clock: initCaptchaPage,
//   target: getSettingsFx,
// });

// sample({
//   clock: submitUpdateSettingsData,
//   target: updateSettingsFx,
// });

// sample({
//   clock: updateSettingsFx.doneData,
//   target: getSettingsFx,
// });

// sample({
//   clock: updateSettingsFx.failData,
//   fn: data => data.response?.data?.message || 'Ошибка при изменении настроек капчи',
//   target: setError,
// });

// sample({
//   clock: updateSettingsFx.doneData,
//   fn: () => 'Капча успешно изменена',
//   target: setSuccess,
// });
