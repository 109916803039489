import { Form } from 'antd';

import { TFormValues } from '../Measurements.types';

export const useForm = () => {
  const [form] = Form.useForm<TFormValues>();

  return {
    form,
  };
};
