import { Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Utils , getFormattedPhone } from 'src/shared/lib';
import { DateType } from 'src/shared/ui';
import { statusColors } from 'src/shared/config';

import { NameProfilesCard } from './ui';
import { Intermediary } from './types';

enum statusesEnum {
  APPROVED = 'Подтвержден',
  REJECTED = 'Не одобрен',
  CHECKING = 'Новый',
  DELETED = 'Удален',
  FULL_DELETED = 'Полностью удален',
}

export const statusOptions = Object.entries(statusesEnum).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const PER_PAGE = 10;
export const mainColumnName = 'name';

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export enum MenuTableEnum {
  EDIT = 'edit',
}


export const renderIntermediaryStatus = (status: string) => {
  switch (status) {
    case 'APPROVED':
      return <Tag style={statusColors.blue}>Подтвержден</Tag>;
    case 'REJECTED':
      return <Tag style={statusColors.red}>Не одобрен</Tag>;
    case 'CHECKING':
      return <Tag style={statusColors.green}>Новый</Tag>;
    case 'DELETED':
      return <Tag style={statusColors.gray}>Удален</Tag>;
    case 'FULL_DELETED':
      return <Tag style={statusColors.lightGray}>Полностью удален</Tag>;
    default:
      return '';
  }
};

export const columns: ColumnsType<Intermediary> = [
  {
    title: 'ФИО',
    key: 'name',
    render: (_, record) => (
      <Link to={`/profile/intermediary/${record.id}`} state={{ prevPath: true }}>
        <NameProfilesCard data={record} />
      </Link>
    ),
  },
  {
    title: 'Номер пропуска',
    dataIndex: 'pass_number',
    key: 'pass_number',
    render: data => data || '-',
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
    render: data => getFormattedPhone(data) || '-',
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: DateType) => (data ? Utils.dateFormat(data) : '-'),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: status => (status ? renderIntermediaryStatus(status) : '-'),
  },
  // TODO: добавить в следующей итерации
  // {
  //   title: '',
  //   key: 'settings',
  //   render: () => (
  //     <Dropdown overlay={menu}>
  //       <a href="#0">
  //         <MoreOutlined />
  //       </a>
  //     </Dropdown>
  //   ),
  // },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);
