import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from '../../LegalEntityCreation.module.less';

interface LegalTooltipProps {
  title: string;
}

export const LegalTooltip = ({ title }: LegalTooltipProps) => (
  <Tooltip title={title} overlayClassName={styles.tooltip} placement="left">
    <QuestionCircleOutlined />
  </Tooltip>
);
