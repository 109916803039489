import { SmileOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useStore } from 'effector-react';
import { IChatItem, UserType } from 'src/shared/types';
import { Utils } from 'src/shared/lib';
import classNames from 'classnames';
import { $currentChatId, selectChatId, resetOldMessagesEvent } from 'src/pages/chat/ui/chatMessages/model';
import { Tooltip } from 'src/shared/ui';

import styles from './ChatItem.module.less';

const getIconByUserType = (userType: UserType, isPlaceholder?: boolean, isTopElement?: boolean) => {
  const isClient = userType === 'CLIENT';

  return isPlaceholder ? (
    <span className={`${styles.avatarPlaceholder} ${isTopElement ? styles.topPlaceholder : ''}`}>
      {isClient ? <SmileOutlined /> : <NodeIndexOutlined />}
    </span>
  ) : (
    <Tooltip title={isClient ? 'Клиент' : 'Посредник'}>{isClient ? <SmileOutlined /> : <NodeIndexOutlined />}</Tooltip>
  );
};

export const ChatItem = ({ item }: { item: IChatItem }) => {
  const { members, message, name } = item;
  const currentChatId = useStore($currentChatId);

  const fistMember = members[0]?.member;
  const secondMember = members[1]?.member;
  const isActive = currentChatId === item.id;

  const handleChooseChat = (id: number) => {
    resetOldMessagesEvent();
    selectChatId(id);
  };

  return (
    <button
      type="button"
      onClick={() => handleChooseChat(item.id)}
      className={classNames(styles.chatItem, { [styles.activeChat]: isActive })}
      disabled={isActive}>
      <div className={styles.chatItemWrapper}>
        <div className={styles.chatItemImage}>
          <Avatar.Group maxCount={2}>
            {fistMember?.avatar ? (
              <Avatar shape="circle" size={22} src={fistMember.avatar} />
            ) : (
              getIconByUserType(fistMember?.user_type, true)
            )}
            {secondMember?.avatar ? (
              <Avatar shape="circle" size={22} src={secondMember.avatar} />
            ) : (
              getIconByUserType(secondMember?.user_type, true, true)
            )}
          </Avatar.Group>
        </div>
        <div className={styles.chatItemInfo}>
          <p className={styles.chatTitle}>
            <span>
              <Tooltip title={fistMember?.name}>{`${fistMember?.name.split(' ')[0]} × `}</Tooltip>
            </span>
            <span>
              <Tooltip title={secondMember?.name}>{secondMember?.name.split(' ')[0]}</Tooltip>
            </span>
          </p>
          <div className={styles.chatItemPreview}>
            <p>{getIconByUserType(fistMember?.user_type)}</p>
            <p>{getIconByUserType(secondMember?.user_type)}</p>
            <p className={styles.chatItemText}>{name}</p>
          </div>
        </div>
      </div>
      {message && <p className={styles.chatItemTime}>{Utils.dateFormatChat(message.created_at, true, true)}</p>}
    </button>
  );
};
