import api from '../api';

export const getTokens = () => api.get('/vk-access-tokens').then(response => response.data);

export const addToken = (data: { code: string, state: string, device_id: string }) => api.post('vk-access-token', data).then(response => response.data);

export const removeToken = (id: number) => api.delete(`vk-access-token/${id}`).then(response => response.data);

export const getTokensLink = (data: { redirect_url: string }) =>
  api.post('/vk-access-token/get-link', data).then(response => response.data);
