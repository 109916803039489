import { Button, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { MainModal } from 'src/shared/ui';
import { DetailedOrder } from 'src/pages/orders/order/types';
import { DefaultOptionType } from 'antd/lib/select';
import { ordersApi, paymentsApi } from 'src/shared/api';
import { TPaymentMethodEntity } from 'src/shared/api/queries/paymentsApi';
import { getNotification } from 'src/utils';
import { refetchOrderData } from 'src/pages/orders/order/model';

import { TFormValues } from './PaymentMethodModal.type';
import styles from './PaymentMethodModal.module.less';

interface IProps {
  readonly onClose: () => void;
  readonly order: DetailedOrder;
}

export const PaymentMethodModal: React.FC<IProps> = ({ onClose, order }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<TPaymentMethodEntity[]>([]);
  const [comission, setComission] = useState<number>();
  const [form] = Form.useForm();
  const watchField = Form.useWatch(['payment_method_id'], form);

  const onSubmit = async (values: TFormValues) => {
    try {
      setLoading(true);
      await ordersApi.setOrderPaymentMethod({
        order_id: order.order.id,
        payment_method_id: values.payment_method_id,
      });
      onClose();
      refetchOrderData();
    } catch (error) {
      getNotification({ error, altMessage: 'Ошибка сохранения способа оплаты' });
    } finally {
      setLoading(false);
    }
  };

  const fetchOptions = async () => {
    setLoading(true);
    const response = await paymentsApi.getPaymentMethods();
    if (response) {
      setOptions(
        response.payment_types.map(i => ({
          label: i.name,
          value: i.id,
        })),
      );
      setPaymentMethods(response.payment_types);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    setComission(paymentMethods.find(i => String(i.id) === String(watchField))?.commissions?.[0]?.value);
  }, [watchField]);

  return (
    <MainModal isOpen closeHandler={onClose}>
      <Form form={form} name='basic' layout='vertical' onFinish={onSubmit} autoComplete='off' disabled={loading}>
        <Form.Item
          label={`Способ оплаты заказа ${order.order.code}`}
          name='payment_method_id'
          rules={[{ required: true, message: 'Выберите способ оплаты' }]}
        >
          <Select options={options} />
        </Form.Item>
        {comission && <p>Комиссия {comission}%</p>}
        <div className={styles.buttons}>
          <Form.Item>
            <Button htmlType='submit' className={styles.button} type='primary' disabled={loading}>
              Сохранить
            </Button>
          </Form.Item>
          <Button onClick={onClose} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </MainModal>
  );
};
