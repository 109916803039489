import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { LogsRequestData } from 'src/shared/api/queries/logsApi';
import { Paginated } from 'src/shared/types';
import { logsApi } from 'src/shared/api';
import { Storage } from 'src/shared/lib';

import { initState, PER_PAGE } from './config';
import { LogDetailsType, LogType } from './types';

export const $logs = createStore<Paginated<LogType> | null>(null);
export const $currentLogId = createStore<string | null>(null);
export const $requestData = createStore<LogsRequestData>(initState);
export const $logDetails = createStore<LogDetailsType | null>(null);
export const $pageSize = createStore<number>(PER_PAGE);
Storage.persist.entity($pageSize, { slice: 'logs', key: 'pageSize' });

export const getLogsFx = createEffect(logsApi.getLogs);
export const getLogDetailsFx = createEffect(logsApi.getLogDetails);
export const restartParserFx = createEffect(logsApi.retryParser);

export const clearModalDetails = createEvent();
export const restartParser = createEvent<string>();
export const changeRequestData = createEvent<LogsRequestData>();
export const openLogDetails = createEvent<string>();
export const changePageSize = createEvent<number>();

$requestData.on(changeRequestData, (_, data) => data);

$logs.on(getLogsFx.doneData, (_, data) => data);
$logDetails.on(getLogDetailsFx.doneData, (_, data) => data);

sample({
  clock: changePageSize,
  target: $pageSize,
});

sample({
  source: $requestData,
  target: getLogsFx,
});

sample({
  clock: openLogDetails,
  target: [$currentLogId, getLogDetailsFx],
});

sample({
  clock: restartParser,
  target: restartParserFx,
});

sample({
  clock: restartParserFx.doneData,
  fn: () => 'Парсер перезапущен',
  target: setSuccess,
});

sample({
  clock: restartParserFx.doneData,
  source: $currentLogId,
  fn: id => id || '',
  filter: id => Boolean(id),
  target: getLogDetailsFx,
});

sample({
  clock: restartParserFx.doneData,
  source: $requestData,
  target: getLogsFx,
});

sample({
  clock: restartParserFx.failData,
  fn: () => 'Ошибка перезапуска',
  target: setError,
});

$logDetails.on(clearModalDetails, () => null);
