import { EOrderStatus } from 'src/shared/api/queries/ordersApi';

export const checkUnpaidOrder = (status: EOrderStatus) =>
  [
    EOrderStatus.notPaid,
    EOrderStatus.paid,
    EOrderStatus.inWork,
    EOrderStatus.redeemed,
    EOrderStatus.inStock,
    EOrderStatus.unfreezError,
    EOrderStatus.refundError,
  ].includes(status);
