import { userHistoryOptions } from 'src/pages/task/config';
import { Utils } from 'src/shared/lib';
import { v4 as uuid } from 'uuid';

import { OrdersDetailedHistory } from '../../types';
import styles from './OrdersStatusHistory.module.less';

interface OrdersStatusHistoryProps {
  data?: OrdersDetailedHistory[];
}

export const OrdersStatusHistory = ({ data }: OrdersStatusHistoryProps) => (
  <section className={styles.wrapper}>
    <div className={styles.list}>
      {data
        ? data.map(({ status_name: status, created_at: createdAt, description: comment, user }) => (
            <div className={styles.card} key={uuid()}>
              <div className={styles.wrapper}>
                <div className={styles.info}>
                  <p className={styles.status}>
                    <strong>{status || '-'}</strong>
                  </p>
                  <p className={styles.date}>
                    <span>{user?.name || '-'}</span>
                    <span>{user?.type ? userHistoryOptions[user.type as keyof typeof userHistoryOptions] : '-'}</span>
                    <span>{createdAt ? Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm') : '-'}</span>
                  </p>
                  <p>{comment}</p>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  </section>
);
