import { TPagination, TPaginationBody } from 'src/utils';

import api from '../api';
import { TDeliveryEntity } from './ordersApi';
import { TFullAddress } from './addressApi';

export enum SettingKeys {
  intermediary_comission = 'intermediary_comission',
  service_comission = 'service_comission',
  frequency_of_checking_new_posts = 'frequency_of_checking_new_posts',
  use_split_payments = 'use_split_payments',
  disabled_tariffs = 'disabled_tariffs',
}

export interface UpdateSettingData {
  key: SettingKeys;
  value: string | null;
}

type TGetDeliveryTariffsResponse = {
  tariffs: TDeliveryTariffEntity[];
};

export type TDeliveryTariffEntity = {
  providerName: string;
  displayName: string;
  tariffId: string;
  provID: number;
  code: string;
  maxWeight: number;
};

type TGetDeliveryOptionsBody = {
  address: Partial<TFullAddress>;
  price: number;
  type?: string;
  client_id: number
  pagination?: TPaginationBody;
};

type TGetDeliveryOptionsResponse = {
  items: TDeliveryEntity[];
  pagination: TPagination;
};

export const getSettings = () => api.post('/settings').then(response => response.data);

export const getTariffs = (): Promise<TGetDeliveryTariffsResponse> =>
  api.get('/../delivery/tariffs').then(response => response.data);

export const updateSetting = (setting: UpdateSettingData) =>
  api.put('/setting', setting).then(response => response.data);

export const getDeliveryOptions = (data: TGetDeliveryOptionsBody): Promise<TGetDeliveryOptionsResponse> =>
  api.post('/../delivery/admin-options', data).then(response => response.data);
