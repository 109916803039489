import { AxiosResponse } from 'axios';
import { IChatItem } from 'src/shared/types';

import api from '../api';
import clientsApi from '../clientsApi';

export interface ChatListRequestData {
  search?: string;
  type?: 'client_support' | 'task' | 'intermediary_support';
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface ChatListResponseData {
  items: IChatItem[];
  pagination: {
    per_page: number;
    current_page: number;
    total: number;
    last_page: number;
  };
}

export interface AttachmentsReqData {
  id: string | number;
  data: {
    type: 'image';
    pagination: {
      per_page: number;
      page: number;
    };
  };
}

export const getChatList = (data: ChatListRequestData) =>
  api.post('/chats', data).then((response: AxiosResponse<ChatListResponseData>) => response.data);

export const getChatInfo = (id: string | number) => api.get(`/chat/${id}`).then(response => response.data);

export const getAttachmentCount = (id: string | number) =>
  api.get(`/chat/${id}/attachment-types-count`).then(response => response.data);

export const getAttachments = (data: AttachmentsReqData) =>
  api.get(`/chat/${data.id}/attachments-by-type`, { params: data.data }).then(response => response.data);

export const getTestChat = () => clientsApi.get('/test/create').then((response: any) => response.data);
