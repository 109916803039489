import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $legalEntitiesOptions, initSuppEditModal } from 'src/pages/profiles/suppliersProfile/model';
import { Button, Form, Input, Spin, AutoComplete } from 'antd';
import { setError } from 'src/features/notifications';
import { PhoneInput } from 'src/entities/phoneInput';
import { Image , getNumbers } from 'src/shared/lib';
import { Title, MainModal, DragAndDrop } from 'src/shared/ui';
import { initStateLegal } from 'src/shared/config';

import { SupplierProfile } from '../../../suppliersProfile';
import { getFileFx } from '../../model';
import styles from './EditModal.module.less';

interface EditModalProps {
  isOpen: boolean;
  loading: boolean;
  profile?: SupplierProfile;
  onClose: () => void;
  handleSupplier: (data: SupplierProfile) => void;
}

export const EditModal = ({ isOpen, loading, profile, onClose, handleSupplier }: EditModalProps) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<File[]>([]);
  const legalEntitiesOptions = useStore($legalEntitiesOptions);
  const [selectedLegalId, setSelectedLegalId] = useState<string>('');
  const [initialLegalValue, setInitialLegalValue] = useState<string>('');
  const [legalValue, setLegalValue] = useState<string>('');

  useEffect(() => {
    const legalData = profile?.legal_entity?.full_name
      ? { ...initStateLegal, full_name: profile.legal_entity.full_name }
      : initStateLegal;
    const legalId = profile?.legal_entity?.id ? profile.legal_entity.id.toString() : '';
    const legalValue = profile?.legal_entity?.full_name ? profile.legal_entity.full_name : '';

    setInitialLegalValue(legalValue);
    setLegalValue(legalValue);
    setSelectedLegalId(legalId);
    initSuppEditModal(legalData);
  }, [profile]);

  useEffect(() => {
    if (profile?.avatar) {
      getInitialFile(profile.avatar);
    }
  }, [profile?.avatar]);

  const getInitialFile = async (url: string) => {
    const file = await getFileFx(url);
    setFileList([file]);
  };

  const formSubmitHandler = async (values: SupplierProfile) => {
    try {
      const avatar = fileList.length > 0 ? await Image.toBase64(fileList[0]) : '';
      handleSupplier({
        ...values,
        pass_date: values?.pass_date ? new Date(values.pass_date).toISOString() : '',
        registration_date: values?.registration_date ? new Date(values.registration_date).toISOString() : '',
        phone: values?.phone ? getNumbers(values.phone) : '',
        avatar: avatar ? `${avatar}` : '',
        legal_entity_id:
          profile?.legal_entity?.id && !selectedLegalId ? profile.legal_entity.id.toString() : selectedLegalId,
      });
    } catch (err) {
      setError('Ошибка загрузки изображения');
    }
  };

  const handleSearchLegal = (value: string) => {
    const legalData = value ? { ...initStateLegal, full_name: value } : initStateLegal;
    initSuppEditModal(legalData);
    setLegalValue(value);
  };

  const handleSelectLegal = (value: string, options: { label: string }) => {
    setSelectedLegalId(value);
    setInitialLegalValue(options.label);
    setLegalValue(options.label);
    form.setFieldsValue({ legal_entity_id: options.label });
  };

  const handleCancelClose = () => {
    form.resetFields();
    onClose();
    const initialLegalId = profile?.legal_entity?.id ? profile.legal_entity.id.toString() : '';
    const legalValue = profile?.legal_entity?.full_name ? profile.legal_entity.full_name : '';
    setSelectedLegalId(initialLegalId);
    setInitialLegalValue(legalValue);
    setLegalValue(legalValue);
  };

  return (
    <MainModal isOpen={isOpen} closeHandler={onClose} width="650px">
      <Spin size="large" spinning={loading}>
        <Title>{profile ? 'Редактирование профиля' : 'Создание профиля'}</Title>
        <Form
          form={form}
          className={styles.editForm}
          name="basic"
          onFinish={formSubmitHandler}
          initialValues={{ ...profile, legal_entity_id: profile?.legal_entity?.full_name || '' }}
          layout="vertical"
          autoComplete="off">
          <Form.Item label="Наименование" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Аватар" name="avatar">
            <DragAndDrop name="avatar" multiple={false} fileList={fileList} setFileList={setFileList} maxCount={1} />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input />
          </Form.Item>
          <Form.Item label="E-mail" name="email" rules={[{ type: 'email', message: 'Не валидный email' }]}>
            <Input />
          </Form.Item>
          <PhoneInput />
          <div className={styles.select}>
            <Form.Item
              rules={[
                {
                  message: 'Юр. лицо заполнено некорректно. Выберете юр. лицо из выпадающего списка.',
                  validator() {
                    if (
                      legalValue !== profile?.legal_entity?.full_name &&
                      profile?.legal_entity?.id &&
                      selectedLegalId === profile.legal_entity.id.toString()
                    ) {
                      return Promise.reject();
                    }
                    if (initialLegalValue !== legalValue) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                  validateTrigger: 'onChange',
                },
              ]}
              label="Юридическое лицо"
              name="legal_entity_id">
              <AutoComplete
                value={selectedLegalId}
                onChange={handleSearchLegal}
                onSelect={handleSelectLegal}
                placeholder="Поиск по юридическим лицам">
                {legalEntitiesOptions?.map(({ value, key, label, disabled }) => (
                  <AutoComplete.Option key={key} label={label} disabled={disabled}>
                    {value}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading} style={{ marginRight: '16px' }}>
              {loading ? <Spin /> : 'Подтвердить'}
            </Button>
            <Button onClick={handleCancelClose}>Отмена</Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainModal>
  );
};
