import React, { Key, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { ClearOutlined } from '@ant-design/icons';
import { Table, Input, Empty, Spin, Button, Tooltip } from 'antd';
import { TableSettings } from 'src/entities/tableSettings';
import { NumberTypeEnum, OrderMeasurements } from 'src/shared/types';
import { Hooks , getFinalFilterPrice } from 'src/shared/lib';
import dayjs from 'dayjs';
import { MainModal } from 'src/shared/ui';
import { Measurements } from 'src/entities/measurements';
import { orderStatus } from 'src/shared/config';

import {
  $orders,
  $order,
  $activeOrderId,
  $ordersStatusOptions,
  $clients,
  $clientsData,
  $labelsForPrint,
  $visibleColumns,
  changeVisibleColumns,
  changeRequestData,
  changeClientsData,
  getOrdersFx,
  initPage,
  viewAssemblyTasks,
  $detailedOrdersForOpen,
  clearViewedTasks,
  printAssemblyTasks,
  submitPrintLabelData,
  submitPrintLabelsData,
  $detailedOrdersForPrint,
  clearPrintedTasks,
  clearLabelsData,
  submitSendToDelivery,
  submitCheckStatuses,
  submitRetryDebitData,
  submitDates,
  submitOrderReqData,
  submitOrderMeasureReqData,
  moveOrderToAssembly,
  createDeliveryFx,
  getAssemblyTaskFx,
  getLabelFx,
  getLabelsFx,
  getAssemblyLabelFx,
  getOrderFx,
  assemblyOrderFx,
  postOrderMeasureFx,
  changePageSize,
  $pageSize,
} from './model';
import { renderColumns, printStatus, PER_PAGE, mainColumnName } from './config';
import { IOrder, LabelCountType } from './types';
import { transformForPrintTable } from './helpers';
import styles from './OrdersList.module.less';
import { AskCourierModal } from './ui';
import { Order } from '../order';
import { useOrdersList } from './hooks/useOrdersList';

interface OrdersListProps {
  status?: number;
  withAssemblyTask?: boolean;
  packing?: boolean;
  waitingCourier?: boolean;
  askSending?: boolean;
  withPayError?: boolean;
  isPickup?: boolean;
}

export const OrdersList = ({
  status,
  withAssemblyTask,
  packing,
  askSending,
  withPayError,
  waitingCourier,
  isPickup,
}: OrdersListProps) => {
  const { setOrderStatus } = useOrdersList();
  const [visibleColumns, setVisibleColumns] = useState(renderColumns({}));
  const visibleColumnsKeys = useStore($visibleColumns);
  const pageSize = useStore($pageSize);
  const [nameValue, setNameValue] = useState('');
  const [selectedRows, setSelectedRows] = useState<IOrder[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const loadingCreationDelivery = useStore(createDeliveryFx.pending);
  const loadingDetailedOrders = useStore(getAssemblyTaskFx.pending);
  const loadingAssemblyOrders = useStore(assemblyOrderFx.pending);
  const orders = useStore($orders);
  const order = useStore($order);
  const activeOrderId = useStore($activeOrderId);
  const statusOptions = useStore($ordersStatusOptions);
  const clientsData = useStore($clientsData);
  const detailedOrdersForOpen = useStore($detailedOrdersForOpen);
  const detailedOrdersForPrint = useStore($detailedOrdersForPrint);
  const clients = useStore($clients);
  const labelsForPrint = useStore($labelsForPrint);
  const isOrdersLoading = useStore(getOrdersFx.pending);
  const isLabelLoading = useStore(getLabelFx.pending);
  const isLabelsLoading = useStore(getLabelsFx.pending);
  const isAssemblyLabelLoading = useStore(getAssemblyLabelFx.pending);
  const isOrderLoading = useStore(getOrderFx.pending);
  const isMeasureLoading = useStore(postOrderMeasureFx.pending);
  const filteredRows = selectedRows.filter((order: IOrder) => order.status === printStatus);
  const [searchParams, setSearchParams] = Hooks.useSearchParamsObject();
  const [isMeasure, setIsMeasure] = useState(false);
  const [orderId, setOrderId] = useState<string>();

  const isButtonDisabled = selectedRows.length < 2;

  useEffect(() => {
    initPage();
  }, []);

  useEffect(() => {
    const currPage = searchParams.page ? +searchParams.page : 1;
    const { page, statuses, ...filters } = searchParams;
    const statusesArray = statuses ? statuses.split(',') : [];
    const resultStatuses =
      !statusesArray.length && status === orderStatus.debitFail ? [status, orderStatus.refundFail] : statusesArray;
    const reqData =
      !statusesArray.length && status !== orderStatus.debitFail ? { status } : { statuses: resultStatuses };

    changeRequestData({
      ...filters,
      ...reqData,
      pagination: { page: currPage, per_page: pageSize },
    });
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [searchParams, pageSize, status]);

  useEffect(() => {
    if (detailedOrdersForOpen?.length) {
      const WinPrint = window.open();
      WinPrint?.document.write(transformForPrintTable(detailedOrdersForOpen));
      clearViewedTasks();
    }
  }, [detailedOrdersForOpen]);

  useEffect(() => {
    if (labelsForPrint) {
      window.open(labelsForPrint.label);
      clearLabelsData();
    }
  }, [labelsForPrint]);

  useEffect(() => {
    if (detailedOrdersForPrint?.length) {
      const WinPrint = window.open('', '');
      WinPrint?.document.write(transformForPrintTable(detailedOrdersForPrint));
      WinPrint?.document.close();
      WinPrint?.focus();
      WinPrint?.print();
      clearPrintedTasks();
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  }, [detailedOrdersForPrint]);

  useEffect(() => {
    const resultVisibleColumns: ColumnsType<IOrder> = renderColumns({ setOrderId }).filter(
      column => column.key && (!(column.key in visibleColumnsKeys) || visibleColumnsKeys[column.key]),
    );
    setVisibleColumns(resultVisibleColumns);
  }, [visibleColumnsKeys]);

  const searchNameHandler = (value: string) => {
    setSearchParams({ ...searchParams, code: value || null, page: 1 });
  };

  const handleSelectClient = (value: number) => {
    setSearchParams({ ...searchParams, client_id: value || null, page: 1 });
  };

  const handleSelectStatus = (value: string[]) =>
    setSearchParams({ ...searchParams, statuses: value || null, page: 1 });

  const handleSelectChange = (selectedRowKeys: Key[], selectedRows: IOrder[]) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<IOrder> = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  };

  const searchPriceHandler = (value: string) => {
    const resultValue = value ? getFinalFilterPrice(value) : null;
    setSearchParams({ ...searchParams, price: resultValue, page: 1 });
  };

  const searchClientHandler = (value: string) => {
    if (value) {
      changeClientsData({ ...clientsData, name: value });
    }
  };

  const resetClientHandler = () => {
    const { client_id: clientId, ...filteredReqData } = searchParams;
    setSearchParams({ ...filteredReqData, page: 1 });
  };

  const searchCreatedDateHandler = (value: string) => {
    setSearchParams({ ...searchParams, created_at: value ? new Date(value).toISOString() : null, page: 1 });
  };

  const handleChangePage = (value: number) => {
    setSearchParams({ ...searchParams, page: value.toString() });
  };

  const handleChangePageSize = (currentSize: number, size: number) => {
    changePageSize(size);
  };

  const handleResetFilters = () => {
    setSearchParams({ page: 1 });
    setIsActiveFilter(true);
    setNameValue('');
  };

  const getPriceSearch = Hooks.useColumnSearch<IOrder>(
    searchPriceHandler,
    NumberTypeEnum.PRICE,
    isActiveFilter,
    setIsActiveFilter,
    searchParams.price,
  );

  const getStatusSearch = Hooks.useColumnMultiSelect<IOrder>(
    handleSelectStatus,
    statusOptions,
    'Выбрать статус',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.status,
  );

  const getClientSearch = Hooks.useColumnAutocomplete<IOrder>(
    searchClientHandler,
    handleSelectClient,
    resetClientHandler,
    clients?.items,
    isActiveFilter,
    setIsActiveFilter,
  );

  const getDateSearch = Hooks.useColumnSearch<IOrder>(
    searchCreatedDateHandler,
    'date',
    isActiveFilter,
    setIsActiveFilter,
    searchParams.created_at,
  );

  const openOrder = (data: IOrder) => {
    viewAssemblyTasks([data]);
  };

  const printOrder = (data: IOrder) => {
    printAssemblyTasks([data]);
  };

  const moveOrder = (data: IOrder) => {
    moveOrderToAssembly([data]);
  };

  const handlePrintLabel = (labelCount: LabelCountType, data: IOrder[], type: string) => {
    if (labelCount === 'multiple') {
      const labelIds = data.map(({ id }) => id);
      submitPrintLabelsData({ ids: labelIds });
    } else {
      const labelId = data[0].id;
      submitPrintLabelData({ id: labelId, type });
    }
  };

  const askCourier = () => {
    const tomorrowDate = {
      fromDate: dayjs().add(+1, 'day').toISOString(),
      toDate: dayjs().add(+2, 'day').toISOString(),
    };
    submitDates(tomorrowDate);
    submitSendToDelivery(selectedRows.map(item => item.id));
    setSelectedRows([]);
  };

  const checkStatuses = () => {
    submitCheckStatuses(orders ? orders.items.map(it => it.id) : []);
  };

  const handleMeasurements = (value: number) => {
    submitOrderReqData(value);
    setIsMeasure(true);
  };

  const handleSubmitMeasurements = (value: OrderMeasurements) => {
    if (activeOrderId) {
      submitOrderMeasureReqData({ id: activeOrderId, data: { dimensions: value } });
    }
    setIsMeasure(false);
  };

  const handlePickupReady = (id: string) => {
    setOrderStatus({
      id,
      status: orderStatus.forPickup,
    });
  };

  const handleComplite = (id: string) => {
    setOrderStatus({
      id,
      status: orderStatus.done,
    });
  };

  let filteredColumns: ColumnsType<IOrder> = visibleColumns.map(column => {
    switch (column.key) {
      case 'price':
        return { ...column, ...getPriceSearch(column.key, column.title) };
      case 'client':
        return { ...column, ...getClientSearch(column.key, column.title) };
      case 'created_at':
        return { ...column, ...getDateSearch(column.key, column.title) };
      case 'status':
        return { ...column, ...getStatusSearch(column.key, column.title) };
      default:
        return column;
    }
  });

  filteredColumns = withAssemblyTask
    ? [
        ...filteredColumns,
        {
          title: 'Сборочное задание',
          key: 'assemblyTask',
          render: data => (
            <div className={styles.buttons}>
              <Button onClick={() => openOrder(data)}>Открыть</Button>
              <Button className={styles.button} onClick={() => printOrder(data)}>
                Печатать
              </Button>
            </div>
          ),
        },
        {
          title: 'Действие',
          key: 'assemblyAction',
          render: data => (
            <Button type='primary' onClick={() => moveOrder(data)}>
              Начать сборку
            </Button>
          ),
        },
      ]
    : filteredColumns;

  filteredColumns = askSending
    ? [
        ...filteredColumns,
        {
          key: 'askSending',
          render: data => (
            <div className={styles.buttons}>
              <Spin spinning={loadingCreationDelivery || isMeasureLoading || isOrderLoading}>
                {data.delivery.id === 'local_pickup' ? (
                  <Button onClick={() => handlePickupReady(data.id)}>Готов к самовывозу</Button>
                ) : (
                  <Button onClick={() => handleMeasurements(data.id)}>Запросить отправку</Button>
                )}
              </Spin>
            </div>
          ),
        },
      ]
    : filteredColumns;

  filteredColumns = isPickup
    ? [
        ...filteredColumns,
        {
          key: 'localPickup',
          render: data => (
            <div className={styles.buttons}>
              <Spin spinning={loadingCreationDelivery || isMeasureLoading || isOrderLoading}>
                <Button onClick={() => handleComplite(data.id)}>Выдан клиенту</Button>
              </Spin>
            </div>
          ),
        },
      ]
    : filteredColumns;

  filteredColumns = withPayError
    ? [
        ...filteredColumns,
        {
          title: 'Повторная попытка списания средств',
          key: 'payError',
          render: data => (
            <div className={styles.buttons}>
              <Button onClick={() => submitRetryDebitData(data.id)}>Повторить попытку списания</Button>
            </div>
          ),
        },
      ]
    : filteredColumns;

  const tableColumns: ColumnsType<IOrder> = packing
    ? [
        ...filteredColumns,
        {
          title: 'Ярлык',
          key: 'label',
          render: data => (
            <Button
              disabled={data.status !== printStatus || isLabelLoading || isLabelsLoading}
              loading={isLabelLoading}
              onClick={() => handlePrintLabel('single', [data], 'label')}
            >
              Печатать
            </Button>
          ),
        },
      ]
    : filteredColumns;

  if (isOrdersLoading) {
    return (
      <div className={styles.center}>
        <Spin size='large' />
      </div>
    );
  }

  return orders ? (
    <div className={styles.ordersList}>
      <div className={styles.searchSettings}>
        <Input.Search
          value={nameValue}
          className={styles.search}
          placeholder='Поиск'
          onChange={handleSearchName}
          onSearch={searchNameHandler}
          allowClear
        />
        <div className={styles.btnWrapper}>
          {withAssemblyTask ? (
            <div className={styles.buttons}>
              <Button
                onClick={() => viewAssemblyTasks(selectedRows)}
                loading={loadingDetailedOrders}
                disabled={isButtonDisabled}
              >
                Открыть сборочные задания
              </Button>
              <Button
                className={styles.button}
                onClick={() => printAssemblyTasks(selectedRows)}
                loading={isAssemblyLabelLoading}
                disabled={isButtonDisabled}
              >
                Распечатать
              </Button>
              <Button
                className={styles.button}
                onClick={() => moveOrderToAssembly(selectedRows)}
                loading={loadingAssemblyOrders}
                disabled={isButtonDisabled}
              >
                Начать сборку
              </Button>
            </div>
          ) : null}

          {waitingCourier ? <Button onClick={checkStatuses}>Обновить статусы</Button> : null}

          {packing ? (
            <div className={styles.buttons}>
              {selectedRows.length ? (
                <Button className={styles.button} onClick={askCourier}>
                  Вызвать курьера
                </Button>
              ) : (
                <Tooltip overlayStyle={{ position: 'fixed' }} title='Выберите хотя бы один заказ для передачи курьеру'>
                  <Button className={styles.button} disabled>
                    Вызвать курьера
                  </Button>
                </Tooltip>
              )}
              {selectedRows.length > 1 ? (
                <Button
                  className={styles.button}
                  disabled={filteredRows.length < 2 || isLabelsLoading || isLabelLoading}
                  loading={isLabelsLoading}
                  onClick={() => handlePrintLabel('multiple', filteredRows, 'label')}
                >
                  Распечатать ярлыки
                </Button>
              ) : null}
            </div>
          ) : null}
          <Tooltip overlayStyle={{ position: 'fixed' }} title='Очистить все фильтры'>
            <ClearOutlined className={styles.clear} onClick={handleResetFilters} />
          </Tooltip>

          <TableSettings
            disabledColumns={[mainColumnName]}
            columns={renderColumns({ setOrderId })}
            visibleColumnsKeys={visibleColumnsKeys}
            setVisibleColumnsKeys={changeVisibleColumns}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        rowSelection={rowSelection}
        rowKey={record => record.id}
        bordered
        columns={tableColumns}
        dataSource={orders?.items}
        pagination={{
          onChange: handleChangePage,
          onShowSizeChange: handleChangePageSize,
          current: orders.pagination?.current_page || 1,
          pageSize: orders.pagination?.per_page || PER_PAGE,
          total: orders.pagination?.total || 0,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <Empty description='Нет данных' /> }}
      />
      <AskCourierModal isOpen={isOpen} closeHandler={() => setIsOpen(false)} onSubmit={askCourier} />

      <MainModal isOpen={isMeasure && !!order} closeHandler={() => setIsMeasure(false)}>
        <Measurements
          order={order}
          successHandler={handleSubmitMeasurements}
          closeHandler={() => setIsMeasure(false)}
          isLoading={isOrderLoading}
        />
      </MainModal>

      <MainModal isOpen={Boolean(orderId)} closeHandler={() => setOrderId(undefined)} width='90%' closeIconFixed>
        <Order orderId={orderId} />
      </MainModal>
    </div>
  ) : (
    <Empty description='Нет данных' />
  );
};
