import React from 'react';
import { ConfirmForm } from 'src/shared/ui';
import { Avatar } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { OrdersProductsInfoProps } from '../OrdersProductsInfo';
import styles from '../OrdersProductsInfo.module.less';
import { TOrderProductInfo } from '../OrdersProductsInfo.types';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  confirmHandler: () => void;
  item: {
    data?: TOrderProductInfo;
    task?: OrdersProductsInfoProps['task'];
  };
}

export const CancelIntermedaryModal: React.FC<IProps> = ({ isOpen, setIsOpen, confirmHandler, item }) => {
  const { data, task } = item;

  if (!task || !data) {
    return null;
  }

  return (
    <ConfirmForm
      isOpen={isOpen}
      title='Снять посредника?'
      closeHandler={() => setIsOpen(false)}
      confirmHandler={() => {
        confirmHandler();
        setIsOpen(false);
      }}
      buttonsPosition='left'
      titlePosition='left'
    >
      <div className={styles.removeIntermediary}>
        <div className={styles.flex}>
          <Avatar
            className={styles.avatar}
            shape='square'
            size={100}
            icon={<PictureOutlined />}
            src={data?.image || ''}
          />
          <div>
            <strong>Задание {data.code || '-'}</strong>
            <p>{task.intermediary?.name}</p>
          </div>
        </div>
        <p>Задание будет отправлено на биржу и доступно всем посредникам</p>
      </div>
    </ConfirmForm>
  );
};
