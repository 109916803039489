import { Form, Input, Select } from 'antd';
import { typeSelectOptions } from 'src/pages/profiles/legalEntitiesProfile/config';
import { LegalAdressType } from 'src/pages/profiles/legalEntitiesProfile/types';
import { useEffect } from 'react';
import { FormInstance } from 'antd/es/form/Form';

import styles from '../LegalEntityCreation.module.less';
import {
  legalEntityFormRules,
  legalEntityFormTooltips,
  legalEntityFieldLength,
  defaultValidateStatus,
} from '../config';
import { LegalTooltip } from './legalTooltip';
import { LegalValidationErrors } from '../types';

export interface Address {
  key: string;
  type: LegalAdressType | null;
  zip: string;
  country: string;
  city: string;
  street: string;
}

interface Props {
  values: Address;
  index: number;
  handleChange: (index: number, value: string, fieldName: string) => void;
  form: FormInstance;
  validationErrors: LegalValidationErrors | null;
}

export const AddressBlock = ({ values, index, handleChange, form, validationErrors }: Props) => {
  useEffect(() => {
    form.setFieldsValue({
      [`type-${index}`]: values?.type,
      [`zip-${index}`]: values?.zip,
      [`city-${index}`]: values?.city,
      [`street-${index}`]: values?.street,
      [`country-${index}`]: values?.country,
    });
  }, [form, values, index]);

  return (
    <>
      <div className={styles.item}>
        <Form.Item
          initialValue={values.type}
          rules={legalEntityFormRules.address}
          label="Тип адреса"
          name={`type-${index}`}
          validateStatus={validationErrors?.[`addresses.${index}.type`]?.length ? 'error' : defaultValidateStatus}
          help={
            validationErrors?.[`addresses.${index}.type`]?.length &&
            validationErrors[`addresses.${index}.type`].join(', ')
          }>
          <Select
            value={values.type}
            options={typeSelectOptions}
            onChange={value => handleChange(index, value, 'type')}
          />
        </Form.Item>
        <LegalTooltip title={legalEntityFormTooltips.address} />
      </div>
      <Form.Item
        initialValue={values.zip}
        rules={legalEntityFormRules.zip}
        label="Почтовый индекс"
        name={`zip-${index}`}
        validateStatus={validationErrors?.[`addresses.${index}.zip`]?.length ? 'error' : defaultValidateStatus}
        help={
          validationErrors?.[`addresses.${index}.zip`]?.length && validationErrors[`addresses.${index}.zip`].join(', ')
        }>
        <Input
          value={values.zip}
          onChange={e => {
            handleChange(index, e.target.value, 'zip');
          }}
        />
      </Form.Item>
      <div className={styles.item}>
        <Form.Item
          initialValue={values.city}
          rules={legalEntityFormRules.city}
          label="Город или населенный пункт"
          name={`city-${index}`}
          validateStatus={validationErrors?.[`addresses.${index}.city`]?.length ? 'error' : defaultValidateStatus}
          help={
            validationErrors?.[`addresses.${index}.city`]?.length &&
            validationErrors[`addresses.${index}.city`].join(', ')
          }>
          <Input value={values.city} onChange={e => handleChange(index, e.target.value, 'city')} />
        </Form.Item>
        <LegalTooltip title={legalEntityFormTooltips.city} />
      </div>
      <div className={styles.item}>
        <Form.Item
          initialValue={values.street}
          rules={legalEntityFormRules.street}
          label="Улица, дом, корпус, квартира, офис"
          name={`street-${index}`}
          validateStatus={validationErrors?.[`addresses.${index}.street`]?.length ? 'error' : defaultValidateStatus}
          help={
            validationErrors?.[`addresses.${index}.street`]?.length &&
            validationErrors[`addresses.${index}.street`].join(', ')
          }>
          <Input value={values.street} onChange={e => handleChange(index, e.target.value, 'street')} />
        </Form.Item>
        <LegalTooltip title={legalEntityFormTooltips.street} />
      </div>
      <div className={styles.item}>
        <Form.Item
          initialValue={values.country}
          rules={legalEntityFormRules.addressCountry}
          label="Код страны по ISO"
          name={`country-${index}`}
          validateStatus={validationErrors?.[`addresses.${index}.country`]?.length ? 'error' : defaultValidateStatus}
          help={
            validationErrors?.[`addresses.${index}.country`]?.length &&
            validationErrors[`addresses.${index}.country`].join(', ')
          }>
          <Input
            maxLength={legalEntityFieldLength.country}
            value={values.country}
            onChange={e => handleChange(index, e.target.value, 'country')}
          />
        </Form.Item>
        <LegalTooltip title={legalEntityFormTooltips.addressCountry} />
      </div>
    </>
  );
};
