import { createEffect, createEvent, createStore, sample } from 'effector';
import { setError, setSuccess } from 'src/features/notifications';
import { settingApi, legalEntitiesApi, suppliersApi } from 'src/shared/api';
import { LegalEntity } from 'src/pages/profiles/legalEntities/types';
import { Supplier } from 'src/pages/profiles/suppliers/types';
import { Paginated } from 'src/shared/types';

import { initLegalState, initSuppState } from './config';
import { Setting } from './types';

export const $setting = createStore<boolean>(false);
export const $legalEntities = createStore<Paginated<LegalEntity> | null>(null);
export const $suppliers = createStore<Paginated<Supplier> | null>(null);
export const $legalReqData = createStore<legalEntitiesApi.LegalEntitiesReqData>(initLegalState);
export const $suppliersReqData = createStore<suppliersApi.SuppRequestData>(initSuppState);

export const initSplittingSetting = createEvent();
export const changeSettingValue = createEvent<boolean>();

export const getSettingsFx = createEffect(settingApi.getSettings);
export const updateSettingFx = createEffect(settingApi.updateSetting);
export const getLegalEntitiesFx = createEffect(legalEntitiesApi.getLegalEntities);
export const getSuppliersFx = createEffect(suppliersApi.getSuppList);

$legalEntities.on(getLegalEntitiesFx.doneData, (_, data) => data);
$suppliers.on(getSuppliersFx.doneData, (_, data) => data);

sample({
  clock: initSplittingSetting,
  target: getSettingsFx,
});

sample({
  clock: initSplittingSetting,
  source: $legalReqData,
  target: getLegalEntitiesFx,
});

sample({
  clock: initSplittingSetting,
  source: $suppliersReqData,
  target: getSuppliersFx,
});

sample({
  clock: getSettingsFx.doneData,
  fn: ({ settings }: { settings: Setting[] }) => {
    const frequencySetting = settings.find(item => item.key === settingApi.SettingKeys.use_split_payments);
    return Boolean(frequencySetting?.value === '1');
  },
  target: $setting,
});

sample({
  clock: changeSettingValue,
  fn: (value: boolean) => ({ key: settingApi.SettingKeys.use_split_payments, value: value ? '1' : '0' }),
  target: updateSettingFx,
});

sample({
  clock: updateSettingFx.doneData,
  fn: () => 'Параметр успешно обновлен',
  target: [setSuccess, initSplittingSetting],
});

sample({
  clock: updateSettingFx.failData,
  fn: () => 'Ошибка обновления',
  target: setError,
});
