import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { ordersApi, settingApi } from 'src/shared/api';
import { getNotification, TSimpleOptionObject } from 'src/utils';
import { TDeliveryEntity } from 'src/shared/api/queries/ordersApi';
import { Form, FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { TFullAddress } from 'src/shared/api/queries/addressApi';
import { TDeliveryTariffEntity } from 'src/shared/api/queries/settingApi';
import { refetchOrderData } from 'src/pages/orders/order';

import { TFetchUpdateProps, TFormValues, TMeasurementsProps } from '../Measurements.types';
import { PARCEL_DEFAULT_VALUES } from '../Measurements.constants';
import { useForm } from './useForm';
import { useAddress } from './useAddress';
import { addressNormalize, defaultFormValues, mapFormValues, mapProviders } from '../Measurements.utils';

type TReturnValues = TMeasurementsProps & {
  readonly tariffs: TSimpleOptionObject[];
  readonly providers: TSimpleOptionObject<number>[];
  readonly form: FormInstance<TFormValues>;
  readonly addressOptions: DefaultOptionType[];
  readonly deliveryAddressOptions: DefaultOptionType[];
  readonly fetchAddress: (search?: string) => void;
  readonly fetchDeliveryAddress: (search?: string) => void;
  readonly setRecipientAddress: React.Dispatch<React.SetStateAction<string>>;
  readonly isTariffsLoading?: boolean;
  readonly newDelivery: TDeliveryEntity;
  readonly defaultValues: TFormValues;
  readonly fetchUpdate: (value: TFetchUpdateProps) => void;
  readonly onCloseForm: () => void;
  readonly allTariffs: TDeliveryTariffEntity[];
  readonly filteredAddresses: TDeliveryEntity[];
  readonly filteredTariffs: TDeliveryTariffEntity[];
};

const MeasureMentsContext = createContext<Partial<TReturnValues>>({});

export const MeasureMentsProvider = ({
  children,
  order,
  successHandler,
  closeHandler,
  isLoading,
  isRefetchOrder,
}: PropsWithChildren<TMeasurementsProps>) => {
  const [allAvailableTariffs, setAllAvailableTariffs] = useState<TDeliveryEntity[]>([]);
  const [allTariffs, setAllTariffs] = useState<TDeliveryTariffEntity[]>([]);
  const [providers, setProviders] = useState<TSimpleOptionObject<number>[]>([]);
  const { form } = useForm();
  const { addressOptions, fetchAddress, allAddreses } = useAddress();
  const [recipientAddress, setRecipientAddress] = useState<string>(form?.getFieldValue(['delivery', 'address']));
  const [isTariffsLoading, setIsTariffsLoading] = useState(false);
  const [newDelivery, setNewDelivery] = useState<TDeliveryEntity>();
  const defaultValues = defaultFormValues(order?.order);
  const [filteredAddresses, setFilteredAddresses] = useState<TDeliveryEntity[]>([]);
  const [filteredTariffs, setFilteredTariffs] = useState<TDeliveryTariffEntity[]>([]);
  const provID = Form.useWatch(['delivery', 'provID'], form);
  const tariffId = Form.useWatch(['delivery', 'tariff_id'], form);
  const deliveryAddress = Form.useWatch(['delivery', 'address'], form);

  const fetchUpdate = async ({ values, callback, onSuccess }: TFetchUpdateProps) => {
    const findAddress = allAddreses.find(i => i.full === (recipientAddress || defaultValues.delivery?.address));

    if (!order?.order.id) {
      return;
    }

    try {
      const data = mapFormValues({
        defaultDelivery: defaultValues.delivery!,
        newDelivery,
        values: values!,
        recipientAddress: findAddress || addressNormalize(order.order.address),
      });

      await ordersApi.updateOrder({
        id: String(order?.order.id),
        data,
      });

      getNotification({ type: 'success', altMessage: 'Данные сохранены' });
      onSuccess();
      successHandler(data.dimensions!);
      if (isRefetchOrder) {
        refetchOrderData();
      }
    } catch (error) {
      getNotification({ error });
    } finally {
      callback();
    }
  };

  const fetchAvailableTariffs = async (address: Partial<TFullAddress>) => {
    if (!order?.order.prices.at_the_beginning.products) {
      getNotification({ altMessage: 'Ошибка, не указана цена товара' });
      return;
    }

    if (!order?.order.client_id) {
      getNotification({ altMessage: 'Ошибка, не указан client_id' });
      return;
    }

    try {
      form.setFieldValue(['delivery', 'provID'], undefined);
      form.setFieldValue(['delivery', 'tariff_id'], undefined);
      setIsTariffsLoading(true);

      const response = await settingApi.getDeliveryOptions({
        address,
        price: order?.order.prices.at_the_beginning.products,
        client_id: order?.order.client_id,
      });

      if (response?.items.length) {
        setAllAvailableTariffs(response.items);
        setProviders(mapProviders(response?.items));
      }
      
      setIsTariffsLoading(false);
      form.setFieldValue(['delivery', 'provID'], defaultValues.delivery?.provID);
      form.setFieldValue(['delivery', 'tariff_id'], defaultValues.delivery?.tariff_id);
    } catch (error: any) {
      if (error.response.status === 425) {
        setTimeout(() => {
          fetchAvailableTariffs(address);
        }, 2000);
      } else {
        getNotification({ error });
        form.setFieldValue(['delivery', 'provID'], defaultValues.delivery?.provID);
        form.setFieldValue(['delivery', 'tariff_id'], defaultValues.delivery?.tariff_id);
      }
    }
  };

  const fetchAllTariffs = async () => {
    const response = await settingApi.getTariffs();
    setAllTariffs(response.tariffs);
  };

  const onCloseForm = () => {
    setNewDelivery(undefined);
    form?.resetFields();
    closeHandler?.();
  };

  useEffect(() => {
    if (order?.order.address) {
      fetchAvailableTariffs(order?.order.address);
    }
  }, [order]);

  useEffect(() => {
    if (provID && allTariffs.length) {
      form.setFieldValue(['delivery', 'address'], undefined);
    }
  }, [provID]);

  useEffect(() => {
    if (provID && allTariffs.length && allAvailableTariffs.length) {
      setFilteredTariffs(
        allTariffs?.filter(
          i =>
            String(i.provID) === String(provID) &&
            allAvailableTariffs.some(t => String(t.tariff_id) === String(i.tariffId)),
        ),
      );
    }
  }, [provID, allTariffs, allAvailableTariffs]);

  useEffect(() => {
    if (tariffId && allAvailableTariffs.length) {
      const addresses = allAvailableTariffs?.filter(i => i.tariff_id === tariffId && i.provID === provID);
      setFilteredAddresses(addresses || []);
    }
  }, [tariffId, allAvailableTariffs]);

  useEffect(() => {
    if (deliveryAddress && allAvailableTariffs.length) {
      const findTariff = allAvailableTariffs.find(i => String(i.address) === String(deliveryAddress));

      if (findTariff?.address) {
        setNewDelivery(findTariff);
      } else {
        setNewDelivery(undefined);
      }
    }
  }, [deliveryAddress]);

  useEffect(() => {
    if (tariffId && allAvailableTariffs.length) {
      form.setFieldValue(['delivery', 'address'], undefined);
      const addresses = allAvailableTariffs?.filter(i => i.tariff_id === tariffId && i.provID === provID);

      if (addresses?.[0]?.address) {
        form.setFieldValue(['delivery', 'address'], addresses?.[0]?.address || undefined);
      } else {
        form.setFieldValue(['delivery', 'address'], defaultValues.recipient?.address);
      }
    }
  }, [tariffId]);

  useEffect(() => {
    if (order?.order) {
      form.setFieldValue(['parcels'], order?.order.parcels?.length ? order?.order.parcels : [PARCEL_DEFAULT_VALUES]);
    }
  }, [order]);

  useEffect(() => {
    const findAddress = allAddreses.find(i => i.full === recipientAddress);
    if (findAddress) {
      form.setFieldValue(['delivery', 'provID'], undefined);
      form.setFieldValue(['delivery', 'tariff_id'], undefined);
      form.setFieldValue(['delivery', 'address'], undefined);

      fetchAvailableTariffs({
        ...findAddress,
        index: findAddress.zip,
      });
    }
  }, [recipientAddress]);

  useEffect(() => {
    fetchAllTariffs();
  }, []);

  const values = useMemo(
    () => ({
      providers,
      order,
      successHandler,
      closeHandler,
      isLoading,
      form,
      addressOptions,
      fetchAddress,
      setRecipientAddress,
      isTariffsLoading,
      newDelivery,
      defaultValues,
      fetchUpdate,
      onCloseForm,
      allTariffs,
      filteredAddresses,
      filteredTariffs,
    }),
    [
      providers,
      order,
      isLoading,
      closeHandler,
      successHandler,
      form,
      addressOptions,
      isTariffsLoading,
      newDelivery,
      defaultValues,
      allTariffs,
      filteredAddresses,
      filteredTariffs,
    ],
  );

  return <MeasureMentsContext.Provider value={values}>{children}</MeasureMentsContext.Provider>;
};

export const useMeasureMents = (): Partial<TReturnValues> => ({
  ...useContext(MeasureMentsContext),
});
