import React from "react";
import {Card} from "antd";
import {useStore} from "effector-react";

import styles from "./OrderRefound.module.less";
import {$orderRefunds} from "../../model";
import {OrderRefund} from "../../types";
import {getFinalPrice, Utils} from "../../../../../shared/lib";


const OrderRefundsCard = () => {
    const orderRefunds = useStore($orderRefunds);
    const groupedRefunds = orderRefunds.reduce((prev,refund) => {
        if (refund.created_at in prev) {
            prev[refund.created_at].push(refund)
        } else {
            prev[refund.created_at] = [refund];
        }
        return prev;
    }, {} as {[key: string]: OrderRefund[]});
    if (orderRefunds.length === 0) {
        return null;
    }

    return (
        <div className={styles.tasksWrapper}>
            <Card>
                <h3 className={styles.title}>
                    Возвраты
                </h3>
                {
                    Object.keys(groupedRefunds).sort().map((groupDate) =>
                    <div className={styles.refundInfoWrapper}>
                        <span className={styles.refundPositionsTitle}>Позиции:</span>
                        {groupedRefunds[groupDate].map((item) =>
                            <p>
                                <span>{`${item.quantity/100} шт "${item.name}"`}</span>
                                <span>{getFinalPrice(item.quantity * item.price / 100)}</span>
                            </p>
                        )}
                        <p>
                            <strong>Итого возвращено:</strong>
                            <span style={{fontWeight: 600}}>{getFinalPrice(groupedRefunds[groupDate].reduce((prev, item) => prev + (item.quantity * item.price / 100), 0))}</span>
                        </p>
                        <p>
                            <span>{groupedRefunds[groupDate]?.[0]?.client_name}</span>
                            <span>{Utils.getFormatDate(groupedRefunds[groupDate]?.[0]?.created_at, 'DD.MM.YYYY HH:mm')}</span>
                        </p>
                    </div>)
                }
            </Card>
        </div>
    )
}

export { OrderRefundsCard };