import { ProductsListRequestData } from 'src/shared/api/queries/productsApi';

export interface DataType {
  id: string;
  name: string;
  category: string;
  description: string;
  price: number;
  quantity: number;
  supplier: string;
  date: Date;
  status: string;
  images: string[];
}

const PER_PAGE = 10;

export const initState: ProductsListRequestData = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};
