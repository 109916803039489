import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/ru';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.locale('ru');

/**
 * Формат даты
 */
export type DateType = Dayjs;

/**
 * Формат даты и времени
 */
export const DateFormatEnum = {
  TIME: 'hh:mm',
  DATE: 'DD.MM.YYYY',
};

/**
 * Сегодняшняя ли дата
 * @param value Дата
 * @returns
 */
export const isTodayDay = (value: Dayjs) => value.isToday();

/**
 * Тот же день или нет
 * @param value Дата
 * @returns
 */
export const isSameDay = (day1: Dayjs | string, day2: Dayjs | string) => dayjs(day2).isSame(day1, 'day');

/**
 * Получить формат времени
 * @param value Дата
 * @returns Строка в формате времени
 */
export const timeFormat = (value?: Dayjs | null) => (value ? getFormatDate(value, DateFormatEnum.TIME) : '-');

/**
 * Получить формат даты
 * @param value Дата
 * @returns Строка в формате даты
 */
export const dateFormat = (value?: Dayjs | null) => (value ? getFormatDate(value, DateFormatEnum.DATE) : '-');

/**
 * Получить дату в необходимом формате
 * @param date Дата
 * @param format Формат даты
 * @returns
 */
export const getFormatDate = (date: Dayjs | string, format: string): string => dayjs(String(date)).format(format);

/**
 * Получить формат даты изменения (сегодня, вчера, 11 Мая)
 * @param value
 * @returns Строка в формате даты
 */
export const dateFormatChat = (value?: Date | string | null, isTodayTime?: boolean, isFormatted?: boolean) => {
  try {
    if (!value) return '';

    const dateFormat = dayjs(value);
    const isTodayDate = isTodayDay(dateFormat);

    if (isTodayDate && isTodayTime) return dayjs(value).format('HH:mm');
    if (isTodayDate) return 'Сегодня';
    if (dateFormat.isYesterday() && !isTodayTime) return 'Вчера';

    const thisYear = new Date().getFullYear();
    const dateYear = new Date(value).getFullYear();

    if (isFormatted) return dayjs(value).format(`DD.MM.YYYY`);
    if (thisYear === dateYear) return dayjs(value).format(`D MMMM`);

    return dayjs(value).format(`D MMMM, YYYY`);
  } catch {
    return '';
  }
};
