import { Card, Empty, Image, Button } from 'antd';
import { IntermediaryStatus } from 'src/pages/profiles/intermediariesProfile/types';
import { useState } from 'react';
import { MainModal } from 'src/shared/ui';

import styles from './ProfilesPass.module.less';

interface ProfilesPassProps {
  data: {
    pass_number: string;
    pass_valid_until: string;
    pass_photo_url?: string;
    status: IntermediaryStatus;
  };
  allowEdit?: boolean;
  editPassHandler: () => void;
}

export const ProfilesPass = ({ data, allowEdit, editPassHandler }: ProfilesPassProps) => {
  const [isOpenView, setIsOpenView] = useState(false);

  const passDate = data.pass_valid_until ? new Date(data.pass_valid_until).toLocaleDateString() : '-';

  return (
    <Card className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.data}>
          <p className={styles.title}>Пропуск</p>
          <div className={styles.block}>
            <p>
              <strong>Номер пропуска: </strong> {data.pass_number || '-'}
            </p>
          </div>
          <article className={styles.block}>
            <p>
              <strong>Действителен до: </strong> {passDate}
            </p>
          </article>
        </div>

        <div className={styles.buttons}>
          <Button className={styles.button} onClick={() => setIsOpenView(true)}>
            Посмотреть
          </Button>

          {allowEdit ? (
            <Button disabled={data.status !== 'APPROVED'} className={styles.button} onClick={editPassHandler}>
              Редактировать
            </Button>
          ) : null}
        </div>
      </div>
      <MainModal isOpen={isOpenView} closeHandler={() => setIsOpenView(false)}>
        {data.pass_photo_url ? (
          <Image preview={false} src={data.pass_photo_url} />
        ) : (
          <Empty description="Нет изображения пропуска" />
        )}
      </MainModal>
    </Card>
  );
};
