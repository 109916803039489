import api from '../api';

type TClientChangeBalanceRequest = {
  userId: string;
  amount: number;
  intermediaryId?: number;
};

export type TClientChangeBalanceResponse = {
  balance: number;
  assetBalances: number[];
};

export const clientBalanceUp = (data: TClientChangeBalanceRequest): Promise<TClientChangeBalanceResponse> =>
  api.post('/billing/top-up-cash', data).then(response => response.data);

export const clientBalanceWithdrawal = (data: TClientChangeBalanceRequest): Promise<TClientChangeBalanceResponse> =>
  api.post('/billing/withdraw-cash', data).then(response => response.data);
