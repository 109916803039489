import { ordersApi } from 'src/shared/api';
import { getNotification, TSimpleStatusObject } from 'src/utils';

import { refetchOrdersList } from '../model';

type TReturnValues = {
  readonly setOrderStatus: (value: TSimpleStatusObject) => Promise<void>;
};

export const useOrdersList = (): TReturnValues => {
  const setOrderStatus = async (data: TSimpleStatusObject) => {
    try {
      await ordersApi.setOrderStatus({
        orders: [data],
      });
      refetchOrdersList();
    } catch (error) {
      getNotification({ error });
    }
  };

  return {
    setOrderStatus,
  };
};
