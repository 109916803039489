import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Spin, Empty } from 'antd';
import { BackArrow } from 'src/entities/backArrow';

import { $user, getUser, getUserFx } from './model';
import { EmployeesInfo } from './ui/employeesInfo';
import styles from './EmployeesProfile.module.less';

export const EmployeesProfile = () => {
  const { id } = useParams<{ id: string }>();

  const user = useStore($user);
  const isLoading = useStore(getUserFx.pending);

  useEffect(() => {
    if (id) {
      getUser(id);
    }
  }, [id]);

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.employeesProfile}>
      <BackArrow />

      <h1 className={styles.title}>Профиль сотрудника</h1>

      {user ? (
        <div className={styles.wrapper}>
          <EmployeesInfo data={user} />
        </div>
      ) : (
        <Empty>Сотрудник не найден</Empty>
      )}
    </div>
  );
};
