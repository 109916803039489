import { Link } from 'react-router-dom';
import { getFormattedPhone } from 'src/shared/lib';
import styles from './OrdersBaseInfo.module.less';

interface OrdersBaseInfoProps {
  title: string;
  data: {
    id?: number;
    name: string;
    phone: string;
  };
}

export const OrdersBaseInfo = ({ title, data }: OrdersBaseInfoProps) => (
  <article className={styles.info}>
    <div>
      <h3 className={styles.title}>
        <Link to={`/profile/client/${data.id}`} state={{ prevPath: true }}>
          {title}
        </Link>
      </h3>
      <p className={styles.name}>
        <strong>ФИО: </strong>
        {(
          <Link to={`/profile/client/${data.id}`} state={{ prevPath: true }}>
            {data.name}
          </Link>
        ) || '-'}
      </p>
      <p className={styles.phone}>
        <strong>Телефон: </strong>
        {getFormattedPhone(data.phone) || '-'}
      </p>
    </div>
  </article>
);
