import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Table, Spin, Button, Empty } from 'antd';
import { MainModal } from 'src/shared/ui/mainModal';
import { ConfirmForm } from 'src/shared/ui';
import { AddAddressReqData } from 'src/shared/api/queries/addressApi';

import { AddAddress } from './ui/addAddress';
import { columns } from './config';
import { Address } from './types';
import {
  $addresses,
  initClientsAddressPage,
  initIdClientsAddressPage,
  submitAddAddressReqData,
  submitUpdateAddressReqData,
  submitRemoveAddressReqData,
  getAddressesFx,
} from './model';
import styles from './ClientsAddress.module.less';

interface ClientsAddressProps {
  id?: string;
}

export const ClientsAddress = ({ id }: ClientsAddressProps) => {
  const [modalState, setModalState] = useState<{ isAdd: boolean; isUpdate: boolean; isRemove: boolean }>({
    isAdd: false,
    isUpdate: false,
    isRemove: false,
  });
  const [activeAddress, setActiveAddress] = useState<Address | null>(null);
  const addresses = useStore($addresses);
  const isLoading = useStore(getAddressesFx.pending);

  useEffect(() => {
    if (id) {
      initClientsAddressPage({ client_id: id });
      initIdClientsAddressPage({ client_id: id });
    }
  }, [id]);

  const handleClose = (type: string): void => setModalState({ ...modalState, [type]: false });

  const handleOpen = (type: string): void => setModalState({ ...modalState, [type]: true });

  const handleChangeAddress = (data: Address, type: string): void => {
    setActiveAddress(data);
    setModalState({ ...modalState, [type]: true });
  };

  const handleAddAddress = (data: AddAddressReqData) => {
    if (id) {
      submitAddAddressReqData({ ...data, client_id: id });
    }
  };

  const handleUpdateAddress = (data: AddAddressReqData): void => {
    submitUpdateAddressReqData({ id: activeAddress!.id, data: { ...data, client_id: activeAddress?.client_id } });
    setModalState({ ...modalState, isUpdate: false });
  };

  const handleRemoveAddress = (): void => {
    submitRemoveAddressReqData(activeAddress!.id);
    setModalState({ ...modalState, isRemove: false });
  };

  return (
    <div className={styles.address}>
      <div className={styles.addAddress}>
        <Button disabled={isLoading} onClick={() => handleOpen('isAdd')}>
          Добавить адрес
        </Button>
      </div>

      {isLoading ? (
        <div className={styles.center}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={addresses?.items || []}
          className={styles.table}
          rowKey={record => record.id}
          bordered
          columns={[
            ...columns,
            {
              title: '',
              key: 'settings',
              render: (_, data) => (
                <Dropdown
                  overlayClassName={styles.menu}
                  placement="bottom"
                  menu={{
                    items: [
                      {
                        key: 'edit',
                        label: 'Редактировать',
                        onClick: () => handleChangeAddress(data, 'isUpdate'),
                      },
                      {
                        key: 'remove',
                        label: 'Удалить',
                        onClick: () => handleChangeAddress(data, 'isRemove'),
                      },
                    ],
                  }}>
                  <MoreOutlined />
                </Dropdown>
              ),
              width: '3%',
            },
          ]}
          locale={{ emptyText: <Empty description="Нет данных" /> }}
        />
      )}

      <MainModal isOpen={modalState.isAdd} closeHandler={() => handleClose('isAdd')}>
        <AddAddress
          title="Добавление адреса"
          closeHandler={() => handleClose('isAdd')}
          successHandler={handleAddAddress}
        />
      </MainModal>

      <MainModal isOpen={modalState.isUpdate} closeHandler={() => handleClose('isUpdate')}>
        <AddAddress
          title="Редактирование адреса"
          data={activeAddress}
          closeHandler={() => handleClose('isUpdate')}
          successHandler={handleUpdateAddress}
        />
      </MainModal>

      <ConfirmForm
        isOpen={modalState.isRemove}
        title="Вы уверены, что хотите удалить адрес?"
        closeHandler={() => handleClose('isRemove')}
        confirmHandler={handleRemoveAddress}
      />
    </div>
  );
};
