import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect, sample, combine } from 'effector';
import { categoriesApi, sourceApi, pavilionsApi, suppliersApi } from 'src/shared/api';
import { setSuccess, setError } from 'src/features/notifications';
import { SelectOptions, Pavilion, Paginated } from 'src/shared/types';

import { sourceDataInitState, pavIdInitState } from './config';
import { Categories, FormData, AddSupPavResData, AddSourceResData } from './types';

export const $categories = createStore<Categories | null>(null);
export const $pavilionsOptions = createStore<SelectOptions>([]);
export const $formData = createStore<FormData | null>(null);
export const $sourceFormData = createStore<sourceApi.AddSourceReqData>(sourceDataInitState);
export const $pavId = createStore<{ supplier_pavilion_id: number }>(pavIdInitState);

export const initAddSourcePage = createEvent();
export const submitAddSourceData = createEvent<FormData>();
export const submitAddSource = createEvent<sourceApi.AddSourceReqData>();
export const initPagePavilions = createEvent<pavilionsApi.getPavilionsReqData>();

export const getCategoriesFx = createEffect(categoriesApi.getCategories);
export const addSourceFx = createEffect<sourceApi.AddSourceReqData, AddSourceResData, AxiosError<{ message: string }>>(
  sourceApi.addSource,
);
export const getPavilionsFx = createEffect(pavilionsApi.getPavilions);
export const addSuppPavFx = createEffect<
  suppliersApi.AddSupplierPavReqData,
  AddSupPavResData,
  AxiosError<{ message: string }>
>(suppliersApi.addSupplierPavilion);

$categories.on(getCategoriesFx.doneData, (_, data) => data);
$pavilionsOptions.on(getPavilionsFx.doneData, (_, data: Paginated<Pavilion>) => {
  const pavilionsOptions = (data?.items || []).map(({ id, name }) => ({ label: name, value: name, key: id, id }));
  return pavilionsOptions;
});
$formData.on(submitAddSourceData, (_, data) => data);
$sourceFormData.on(submitAddSourceData, (_, data) => data.sourceData);
$pavId.on(addSuppPavFx.doneData, (_, data) => ({
  supplier_pavilion_id: data.id,
}));

sample({
  clock: initAddSourcePage,
  target: getCategoriesFx,
});

sample({
  clock: initPagePavilions,
  target: getPavilionsFx,
});

sample({
  clock: submitAddSourceData,
  fn: $formData => $formData.suppPavData,
  target: addSuppPavFx,
});

sample({
  clock: submitAddSource,
  target: addSourceFx,
});

sample({
  clock: addSuppPavFx.doneData,
  source: combine($sourceFormData, $pavId, (sourceFormData, pavId) => ({ ...sourceFormData, ...pavId })),
  target: addSourceFx,
});

sample({
  clock: addSuppPavFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка привязки павильона к поставщику',
  target: setError,
});

sample({
  clock: addSourceFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при добавлении источника',
  target: setError,
});

sample({
  clock: addSourceFx.doneData,
  fn: () => 'Источник успешно добавлен',
  target: setSuccess,
});
