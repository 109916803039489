import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import styles from './PaymentMethod.module.less';
import { DetailedOrder } from '../../types';
import { checkUnpaidOrder } from './PaymentMethod.utils';
import { PaymentMethodModal } from './compoennts';

interface IProps {
  readonly order: DetailedOrder;
}

export const PaymentMethod: React.FC<IProps> = ({ order }) => {
  const { order: orderDetails } = order;
  const { status, payment_type: paymentType, prices } = orderDetails;
  const isUnpaid = checkUnpaidOrder(status);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <article className={styles.ordersStatus}>
        <div className={styles.info}>
          <div className={styles.wrapper}>
            <h3 className={styles.title}>Оплата {paymentType}</h3>
            <strong>
              {isUnpaid && (
                <div className={styles.button} onClick={() => setShowModal(true)} role='presentation'>
                  <EditOutlined />
                </div>
              )}
            </strong>
          </div>
          <p>{prices.now.margin_percent}% комиссия</p>
        </div>
      </article>
      {showModal && <PaymentMethodModal order={order} onClose={() => setShowModal(false)} />}
    </>
  );
};
