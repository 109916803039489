import { AutoComplete, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'src/utils';

import styles from '../../Measurements.module.less';
import { useMeasureMents } from '../../hooks/useMeasureMents';

export const RecipientForm: React.FC = () => {
  const { addressOptions, fetchAddress, form, setRecipientAddress } = useMeasureMents();
  const [search, setSearch] = useState<string>(form?.getFieldValue(['delivery', 'address']));
  const debouncedValue = useDebounce(search?.toLocaleLowerCase() || '');

  useEffect(() => {
    fetchAddress?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <div className={styles.group}>
      <p className={styles.groupTitle}>Получатель</p>
      <Form.Item
        label='ФИО получателя'
        name={['recipient', 'name']}
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Input placeholder='Иван Иванов' />
      </Form.Item>
      <Form.Item
        label='Телефон получателя'
        name={['recipient', 'phone']}
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Input placeholder='+7 (999) 999–99–99' />
      </Form.Item>

      <Form.Item
        label='Адрес получателя'
        name={['recipient', 'address']}
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <AutoComplete
          options={addressOptions}
          onSelect={setRecipientAddress}
          onChange={setSearch}
          placeholder='Введите адрес получателя'
        />
      </Form.Item>
    </div>
  );
};
