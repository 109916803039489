import { AutoComplete, Form, Select } from 'antd';
import React, { useState } from 'react';
import { useMeasureMents } from 'src/entities/measurements/hooks/useMeasureMents';

import styles from '../../Measurements.module.less';
import { FORM_AVAILABLE } from '../../Measurements.constants';

export const DeliveryForm: React.FC = () => {
  const { providers, form, order, isTariffsLoading, newDelivery, filteredAddresses, filteredTariffs, defaultValues } =
    useMeasureMents();
  const [search, setSearch] = useState<string>();
  const parcels = form?.getFieldValue(['parcels']);
  const provID = Form.useWatch(['delivery', 'provID'], form);
  const tariffId = Form.useWatch(['delivery', 'tariff_id'], form);
  const disabled = !FORM_AVAILABLE.changeDelivery.includes(order!.order.status);

  const handleProviderChange = () => {
    form?.setFieldValue(['delivery', 'id'], undefined);
    form?.setFieldValue(['delivery', 'address'], undefined);
  };

  const renderDelivery = ({ period, price, index = -1 }: { price?: number; period?: string; index?: number }) => (
    <div>
      <div className={styles.groupItem}>
        <span>Доставка{index >= 0 && ` (посылка ${index + 1})`}</span>
        <span>{price ? `${(price || 0) / 100}` : '-'} ₽</span>
      </div>
      <div className={styles.groupItem}>
        <span>Срок доставки</span>
        <span>{period || '-'}</span>
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.group}>
        <p className={styles.groupTitle}>Транспортная компания</p>
        <Form.Item
          label='Название'
          name={['delivery', 'provID']}
          rules={[
            {
              required: true,
              message: 'Обязательное поле',
            },
          ]}
        >
          <Select
            options={providers}
            placeholder='Выберите компанию'
            onChange={handleProviderChange}
            loading={isTariffsLoading}
            disabled={isTariffsLoading || disabled}
          />
        </Form.Item>
        <Form.Item
          label='Тариф'
          name={['delivery', 'tariff_id']}
          rules={[
            {
              required: true,
              message: 'Обязательное поле',
            },
          ]}
        >
          <Select
            options={
              filteredTariffs?.map(i => ({
                value: i.tariffId,
                label: i.displayName,
              })) || []
            }
            placeholder='Выберите тариф'
            loading={isTariffsLoading}
            disabled={isTariffsLoading || !provID || disabled}
          />
        </Form.Item>
        <Form.Item
          label='Адрес получения (доставки)'
          name={['delivery', 'address']}
          rules={[
            {
              required: true,
              message: 'Обязательное поле',
            },
          ]}
        >
          <AutoComplete
            options={filteredAddresses?.map(i => ({
              value: i.address,
              label: i.address,
            }))}
            onChange={setSearch}
            onSelect={() => setSearch(undefined)}
            placeholder='Введите адрес получения (доставки)'
            disabled={isTariffsLoading || !tariffId || disabled}
            filterOption={(_, option) => !search || option?.label.toLowerCase().includes(search.toLowerCase()) || false}
          />
        </Form.Item>
      </div>
      <div className={styles.group}>
        <p className={styles.groupTitle}>При оформлении клиентом:</p>
        {renderDelivery({
          price: order?.order?.delivery?.price,
          period: order?.order?.delivery.delivery_period,
        })}
      </div>

      {newDelivery &&
        (defaultValues?.delivery?.price !== newDelivery.price ||
          defaultValues?.delivery.delivery_period !== newDelivery.delivery_period) && (
          <div className={styles.group}>
            <p className={styles.groupTitle}>С новыми данными:</p>
            {parcels?.length ? (
              <div className={styles.deliveryList}>
                {parcels?.map((_: any, index: number) => (
                  <React.Fragment key={`parcel-${index}`}>
                    {renderDelivery({
                      price: newDelivery?.price,
                      period: newDelivery.delivery_period,
                      index,
                    })}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              renderDelivery({
                price: newDelivery?.price,
                period: newDelivery.delivery_period,
              })
            )}
          </div>
        )}
    </>
  );
};
